import { BaseSelectionWidgetTwoColumns } from '../../core/components/baseSelectionWidgetTwoColumns';

export class CoverTypesWidget extends BaseSelectionWidgetTwoColumns {
  constructor(props) {
    super(props);
    this._hasConfirmModal = true;
  }

  get name() {
    return 'coverType';
  }

  get label() {
    return this.i18n.gettext('tipo di copertina');
  }

  shouldComponentShowConfirmModal() {
    return (
      this.clickedItem.attributes.code === 'MATERIAL' &&
      this.props.delegate.isCoverEditorProjectValid()
    );
  }

  async onModalConfirmClick() {
    this.props.delegate.resetPhotographicBook();
  }
}
