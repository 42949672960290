import { BaseSelectionWidgetTwoColumns } from '../../core/components/baseSelectionWidgetTwoColumns';

export class UvGraphicWidget extends BaseSelectionWidgetTwoColumns {
  get name() {
    return 'coverUvGraphicType';
  }

  get showWidget() {
    return false;
  }

  get label() {
    return this.i18n.gettext('grafica con stampa uv');
  }
}
