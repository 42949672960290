import { BaseSelectionWidgetTwoColumns } from '../../core/components/baseSelectionWidgetTwoColumns';

export class PaperLaminationTypesWidget extends BaseSelectionWidgetTwoColumns {
  get name() {
    return 'paperLaminationType';
  }

  get label() {
    return this.i18n.gettext('plastificazione carta');
  }
}
