import { BaseSelectionWidgetWithImages } from '../../core/components/baseSelectionWidgetWithImages';

export class DecorationTypesWidget extends BaseSelectionWidgetWithImages {
  get name() {
    return 'decorationType';
  }

  get label() {
    return this.i18n.gettext('decoration');
  }
}
