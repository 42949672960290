//import {BaseSelectionWidgetTwoColumns} from '../../core/components/baseSelectionWidgetTwoColumns';
import { BaseSelectionWidgetWithImages } from '../../core/components/baseSelectionWidgetWithImages';

export class FlyleafTypesWidget extends BaseSelectionWidgetWithImages {
  get name() {
    return 'flyleafType';
  }

  get label() {
    return this.i18n.gettext('risguardo');
  }
}
