import { BaseModel } from '../core/models';
import { conf } from 'outlinejs';
import { EventBookRasterizeCollection } from './managers';

export class EventBookProduct extends BaseModel {
  static get modelName() {
    return 'product';
  }

  get id() {
    return this.get('code');
  }

  get frontAssetType() {
    return this.get('frontAssetType');
  }

  get frontAssetUrl() {
    return this.get('frontAssetUrl');
  }

  get stencilAssetType() {
    return this.get('stencilAssetType');
  }

  get stencilAssetUrl() {
    return this.get('stencilAssetUrl');
  }

  get svgAssetType() {
    return this.get('svgAssetType');
  }

  get svgAssetUrl() {
    return this.get('svgAssetUrl');
  }

  get uvGraphicColor() {
    return this.get('stencilAssetType');
  }

  get debossingColor() {
    return this.get('debossingElementColor');
  }
}

export class EventBookConfiguration extends BaseModel {
  rasterize() {
    return new EventBookRasterizeCollection({ configurationId: this.id }).fetch();
  }

  getAbsoluteUrl() {
    return this.urlRoot + this.id + '/';
  }

  get urlRoot() {
    return conf.settings.EVENT_BOOK_CONFIGURATIONS_URL;
  }

  get productType() {
    return 'EventBook';
  }

  get id() {
    return this.get('id');
  }

  get configurationGuid() {
    return this.get('code');
  }

  get previewBookCode() {
    return this.get('previewBookCode');
  }

  get shopCode() {
    return this.get('shopCode');
  }

  set shopCode(value) {
    this.set('shopCode', value);
  }

  get pagesNumber() {
    return this.get('pagesNumber');
  }

  get sheetsNumber() {
    return this.get('pagesNumber') / 2;
  }

  get paperType() {
    return this.get('paperType');
  }

  get paperLaminationType() {
    return this.get('paperLaminationType');
  }

  get orientationType() {
    return this.get('orientationType');
  }

  get flyleafType() {
    return this.get('flyleafType');
  }

  get flyleafColorMaterialType() {
    return this.get('flyleafColorMaterialType');
  }

  get coverUvTexts() {
    return this.get('coverUvTexts');
  }

  get plaqueType() {
    return this.get('plaqueType');
  }

  get plaqueTexts() {
    return this.get('plaqueTexts');
  }

  get serviceConfigurationTypeCode() {
    return this.get('serviceConfigurationTypeCode');
  }

  set serviceConfigurationTypeCode(value) {
    this.set('serviceConfigurationTypeCode', value);
  }

  get serviceConfigurationTypeName() {
    return this.get('serviceConfigurationTypeName');
  }

  get serviceConfigurationType() {
    return this.get('serviceConfigurationType');
  }

  get urlServiceConfiguration() {
    return this.get('urlServiceConfiguration');
  }

  get bindingType() {
    return this.get('bindingType');
  }

  get eventType() {
    return this.get('eventType');
  }

  get formatType() {
    return this.get('formatType');
  }

  get coverType() {
    return this.get('coverType');
  }

  get coverUvGraphicType() {
    return this.get('coverUvGraphicType');
  }

  get coverUvGraphicThemeType() {
    return this.get('coverUvGraphicThemeType');
  }

  get coverUvGraphicElementType() {
    return this.get('coverUvGraphicElementType');
  }

  get coverColorMaterialType() {
    return this.get('coverColorMaterialType');
  }

  get decorationType() {
    return this.get('decorationType');
  }

  get debossingType() {
    return this.get('debossingType');
  }

  get debossingElementType() {
    return this.get('debossingElementType');
  }

  set debossingElementType(value) {
    this.set('debossingElementType', value);
  }

  get debossingElementFormatType() {
    return this.get('debossingElementFormatType');
  }

  set debossingElementFormatType(value) {
    this.set('debossingElementFormatType', value);
  }

  get debossingTexts() {
    return this.get('debossingTexts');
  }

  set debossingTexts(value) {
    this.set('debossingTexts', value);
  }

  get debossingElementColorType() {
    return this.get('debossingElementColorType');
  }

  set debossingElementColorType(value) {
    this.set('debossingElementColorType', value);
  }

  get coverMaterialType() {
    return this.get('coverMaterialType');
  }

  get coverColorType() {
    return this.get('coverColorType');
  }

  get coverPaddingType() {
    return this.get('coverPaddingType');
  }

  get coverEditorProjectId() {
    return this.get('coverEditorProjectId');
  }

  set coverEditorProjectId(value) {
    this.set('coverEditorProjectId', value);
  }

  get coverLaminationType() {
    return this.get('coverLaminationType');
  }

  get aeVeloceProjectId() {
    return this.get('aeVeloceProjectId');
  }

  get aeVeloceBlockId() {
    return this.get('aeVeloceBlockId');
  }

  get urlFullCoverImage() {
    return this.get('urlFullCoverImage');
  }

  get firstName() {
    return this.get('firstName');
  }

  set firstName(value) {
    this.set('firstName', value);
  }

  get secondName() {
    return this.get('secondName');
  }

  set secondName(value) {
    this.set('secondName', value);
  }

  get eventLocation() {
    return this.get('eventLocation');
  }

  set eventLocation(value) {
    this.set('eventLocation', value);
  }

  get eventStyle() {
    return this.get('eventStyle');
  }

  set eventStyle(value) {
    this.set('eventStyle', value);
  }

  get eventDate() {
    return this.get('eventDate');
  }

  set eventDate(value) {
    this.set('eventDate', new Date(value).toJSON());
  }

  get fullServiceDesignRequested() {
    return this.get('fullServiceDesignRequested');
  }

  set fullServiceDesignRequested(value) {
    this.set('fullServiceDesignRequested', value);
  }

  get fullServiceDesignCompleted() {
    return this.get('fullServiceDesignCompleted');
  }

  set fullServiceDesignCompleted(value) {
    this.set('fullServiceDesignCompleted', value);
  }

  get colorCorrectionCode() {
    return this.get('colorCorrectionCode');
  }

  set colorCorrectionCode(value) {
    this.set('colorCorrectionCode', value);
  }
}

export class EventBookPrice extends BaseModel {
  static get modelName() {
    return 'price';
  }

  get price() {
    return this.get('price');
  }

  get formattedPrice() {
    return this.get('formattedPrice');
  }

  get currency() {
    return this.get('currency');
  }
}

export class EventBookBindingType extends BaseModel {
  static get modelName() {
    return 'bindingType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get minSpreadsNumber() {
    return this.get('minSpreadsNumber') || this.get('min');
  }

  get maxSpreadsNumber() {
    return this.get('maxSpreadsNumber') || this.get('max');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class EventBookEventType extends BaseModel {
  static get modelName() {
    return 'eventType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class EventBookPaperType extends BaseModel {
  static get modelName() {
    return 'paperType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class EventBookPaperLaminationType extends BaseModel {
  static get modelName() {
    return 'paperLaminationType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class EventBookOrientationType extends BaseModel {
  static get modelName() {
    return 'orientationType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class EventBookFormatType extends BaseModel {
  static get modelName() {
    return 'formatType';
  }

  get id() {
    return this.get('code');
  }

  get nameCm() {
    return this.get('nameCm');
  }

  get nameIn() {
    return this.get('nameIn');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class EventBookCoverType extends BaseModel {
  static get modelName() {
    return 'coverType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class EventBookCoverLaminationType extends BaseModel {
  static get modelName() {
    return 'coverLaminationType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class EventBookCoverColorMaterialType extends BaseModel {
  static get modelName() {
    return 'coverColorMaterialType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get description() {
    return this.get('description');
  }

  get bookImageUrl() {
    return this.get('bookImageUrl');
  }

  get isDefault() {
    return this.get('isDefault');
  }

  get uvGraphicColor() {
    let color = this.get('uvGraphicColor');
    return `#${color}`;
  }
}

export class EventBookCoverMaterialType extends BaseModel {
  static get modelName() {
    return 'coverMaterialType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get description() {
    return this.get('description');
  }

  get isDefault() {
    return this.get('isDefault');
  }

  get categoryCode() {
    return this.get('categoryCode');
  }
}

export class EventBookCoverColorType extends BaseModel {
  static get modelName() {
    return 'coverColorType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get description() {
    return this.get('description');
  }

  get bookImageUrl() {
    return this.get('bookImageUrl');
  }

  get isDefault() {
    return this.get('isDefault');
  }

  get uvGraphicColor() {
    let color = this.get('uvGraphicColor');
    return `#${color}`;
  }
}

export class EventBookCoverPaddingType extends BaseModel {
  static get modelName() {
    return 'coverPaddingType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }
}

export class EventBookUvGraphicType extends BaseModel {
  static get modelName() {
    return 'coverUvGraphicType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class EventBookUvGraphicThemeType extends BaseModel {
  static get modelName() {
    return 'coverUvGraphicThemeType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class EventBookUvGraphicElementType extends BaseModel {
  static get modelName() {
    return 'coverUvGraphicElementType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get svgUrl() {
    return this.get('svgUrl');
  }

  get uvGraphicColor() {
    let color = this.get('uvGraphicColor');
    return `#${color}`;
  }

  get isDefault() {
    return this.get('isDefault');
  }

  get svgText1MaxLength() {
    return this.get('svgText1MaxLength');
  }

  get svgText2MaxLength() {
    return this.get('svgText2MaxLength');
  }
}

export class EventBookUvGraphicText extends BaseModel {
  static get modelName() {
    return 'coverUvTexts';
  }

  get id() {
    return this.get('codeRow1');
  }

  get codeRow1() {
    return this.get('codeRow1');
  }

  get nameRow1() {
    return this.get('nameRow1');
  }

  get charLimitRow1() {
    return this.get('charLimitRow1');
  }

  get isRequiredRow1() {
    return this.get('isRequiredRow1');
  }

  get codeRow2() {
    return this.get('codeRow2');
  }

  get nameRow2() {
    return this.get('nameRow2');
  }

  get charLimitRow2() {
    return this.get('charLimitRow2');
  }

  get isRequiredRow2() {
    return this.get('isRequiredRow2');
  }
}

export class EventBookFlyleafType extends BaseModel {
  static get modelName() {
    return 'flyleafType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class EventBookFlyleafColorMaterialType extends BaseModel {
  static get modelName() {
    return 'flyleafColorMaterialType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class EventBookPlaqueType extends BaseModel {
  static get modelName() {
    return 'plaqueType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class EventBookPlaqueText extends BaseModel {
  static get modelName() {
    return 'plaqueTexts';
  }

  get id() {
    return this.get('codeRow1');
  }

  get codeRow1() {
    return this.get('codeRow1');
  }

  get nameRow1() {
    return this.get('nameRow1');
  }

  get charLimitRow1() {
    return this.get('charLimitRow1');
  }

  get isRequiredRow1() {
    return this.get('isRequiredRow1');
  }

  get codeRow2() {
    return this.get('codeRow2');
  }

  get nameRow2() {
    return this.get('nameRow2');
  }

  get charLimitRow2() {
    return this.get('charLimitRow2');
  }

  get isRequiredRow2() {
    return this.get('isRequiredRow2');
  }
}

export class EventBookDecorationType extends BaseModel {
  static get modelName() {
    return 'decorationType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get description() {
    return this.get('description');
  }
}

export class EventBookDebossingType extends BaseModel {
  static get modelName() {
    return 'debossingType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class EventBookDebossingElementFormatType extends BaseModel {
  static get modelName() {
    return 'debossingElementFormatType';
  }

  get id() {
    return this.get('elementFormatTypeCode');
  }

  get name() {
    return this.get('name');
  }

  get code() {
    return this.get('code');
  }

  get isDefault() {
    return this.get('isDefault');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get debossingColor() {
    let color = this.get('debossingColor');
    return `#${color}`;
  }

  get svgText1MaxLength() {
    return this.get('svgText1MaxLength');
  }

  get svgText2MaxLength() {
    return this.get('svgText2MaxLength');
  }

  get svgUrl() {
    return this.get('svgUrl');
  }
}

export class EventBookDebossingElementColorType extends BaseModel {
  static get modelName() {
    return 'debossingElementColorType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get colorCode() {
    let color = this.get('colorCode');
    return `#${color}`;
  }

  get isMetallicColor() {
    return this.get('isMetallicColor');
  }

  get isSuggested() {
    return this.get('isSuggested');
  }

  get orderIndex() {
    return this.get('orderIndex');
  }
}

export class EventBookDebossingTextType extends BaseModel {
  static get modelName() {
    return 'debossingTexts';
  }

  get id() {
    return this.get('rowCode');
  }

  get rowCode() {
    return this.get('rowCode');
  }

  get contentType() {
    return this.get('contentType');
  }

  get fieldType() {
    return this.get('fieldType');
  }

  get isRequired() {
    return this.get('isRequired');
  }

  get maxTextLength() {
    return this.get('maxTextLength');
  }

  get minTextLength() {
    return this.get('minTextLength');
  }

  get rowNumber() {
    return this.get('rowNumber');
  }

  get name() {
    return this.get('name');
  }
}

export class AeCoverEditorProject extends BaseModel {
  get urlRoot() {
    return conf.settings.COVER_EDITOR_PROJECT_URL;
  }

  get id() {
    return this.get('id');
  }

  get projectType() {
    return this.get('projectType');
  }

  set projectType(value) {
    this.set('projectType', value);
  }

  get shopCode() {
    return this.get('shopCode');
  }

  set shopCode(value) {
    this.set('shopCode', value);
  }

  get language() {
    return this.get('language');
  }

  set language(value) {
    this.set('language', value);
  }

  get productConfigurationId() {
    return this.get('productConfigurationId');
  }

  set productConfigurationId(value) {
    this.set('productConfigurationId', value);
  }

  get productConfigurationType() {
    return this.get('productConfigurationType');
  }

  set productConfigurationType(value) {
    this.set('productConfigurationType', value);
  }

  get coverId() {
    return this.get('coverId');
  }

  set coverId(value) {
    this.set('coverId', value);
  }

  get init() {
    return this.get('init');
  }

  set init(value) {
    this.set('init', value);
  }

  get saved() {
    return this.get('saved');
  }

  set saved(value) {
    this.set('saved', value);
  }

  get applicationUrl() {
    return this.get('applicationUrl');
  }
}

export class PhotographicBookCover extends BaseModel {
  get urlRoot() {
    return conf.settings.COVER_URL;
  }

  get cardBoardHeight() {
    return this.get('cardBoardHeight');
  }

  set cardBoardHeight(value) {
    this.set('cardBoardHeight', value);
  }

  get cardBoardWidth() {
    return this.get('cardBoardWidth');
  }

  set cardBoardWidth(value) {
    this.set('cardBoardWidth', value);
  }

  get channelWidth() {
    return this.get('channelWidth');
  }

  set channelWidth(value) {
    this.set('channelWidth', value);
  }

  get spineWidth() {
    return this.get('spineWidth');
  }

  set spineWidth(value) {
    this.set('spineWidth', value);
  }

  get padding() {
    return this.get('padding');
  }

  set padding(value) {
    this.set('padding', value);
  }

  get borderThickness() {
    return this.get('borderThickness');
  }

  set borderThickness(value) {
    this.set('borderThickness', value);
  }

  get paperType() {
    return this.get('paperType');
  }

  set paperType(value) {
    this.set('paperType', value);
  }

  get urlSvg() {
    return this.get('urlSvg');
  }

  get svgContent() {
    return this.get('svgContent');
  }

  get images() {
    return this.get('images');
  }

  get urlRasterizedImage() {
    return this.get('urlRasterizedImage');
  }

  set urlRasterizedImage(value) {
    this.set('urlRasterizedImage', value);
  }
}

export class EventBookCoverProductionInfo extends BaseModel {
  get cardBoardHeight() {
    return this.get('cardBoardHeight');
  }

  get cardBoardWidth() {
    return this.get('cardBoardWidth');
  }

  get channelWidth() {
    return this.get('channelWidth');
  }

  get spineWidth() {
    return this.get('spineWidth');
  }

  get padding() {
    return this.get('paddingWidth');
  }

  get borderThickness() {
    return this.get('borderThickness');
  }

  get paperType() {
    return this.get('paperType');
  }
}

export class EventBookRasterize extends BaseModel {
  get fullCoverImageUrl() {
    return this.get('fullCoverImageUrl');
  }

  get fullCoverImageRasterizationStatus() {
    return this.get('fullCoverImageRasterizationStatus');
  }
}

export class ConfigurationFile extends BaseModel {
  get urlRoot() {
    return conf.settings.EVENT_BOOK_CONFIGURATION_FILES_URL;
  }

  get id() {
    return this.get('id');
  }

  get configurationId() {
    return this.get('configurationId');
  }

  get fileType() {
    return this.get('fileType');
  }

  get fileName() {
    return this.get('fileName');
  }

  get uploadUrl() {
    return this.get('uploadUrl');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get uploaded() {
    return this.get('uploaded');
  }

  get legacy() {
    return this.get('legacy');
  }
}
