import React from 'react';

import classNames from 'classnames';

import { BaseCoverEditorWidget } from '../../core/components/baseCoverEditorWidget';

/*
 * Render button and open cover editor into an iframe.
 * */
export class PhotographicCoverEditorWidget extends BaseCoverEditorWidget {
  visibleCLass() {
    return classNames('block__widget', { hide: !this.props.delegate.hasCoverEditorWidget() });
  }

  async downloadTemplateClick() {
    if (!this.loading) {
      this.props.delegate.showDownloadTemplateDialog();
    }
  }

  renderItems() {
    return (
      <div className="col-xs-12 col-sm-12 col-md-12 no-padding-col">
        <div className="col-xs-6 col-sm-6 col-md-6 no-padding-col">
          <div
            className="btn-ae text-center"
            onClick={this.handleClick}
            data-action="set_cover_editor_button">
            {this.buttonLabel}
          </div>
        </div>
        <div className="col-xs-6 col-sm-6 col-md-6 no-padding-col">
          <div
            className="btn-ae text-center"
            onClick={this.downloadTemplateClick.bind(this)}
            data-action="set_cover_editor_button">
            {'Download Template'}
          </div>
        </div>
      </div>
    );
  }
}
