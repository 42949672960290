import { BaseSheetsNumberWidget } from '../../core/components/baseSheetsNumberWidget';

export class SheetsNumberWidget extends BaseSheetsNumberWidget {
  async onModalConfirmClick() {
    this.props.delegate.resetPhotographicBook();
    await this.setState({ isChosen: true, value: this.tempvalue });
    this.doChange();
  }

  shouldComponentShowConfirmModal() {
    return (
      this.props.delegate.hasCoverEditorWidget() && this.props.delegate.isCoverEditorProjectValid()
    );
  }
}
