import { BaseSelectionWidgetFourColumns } from '../../core/components/baseSelectionWidgetFourColumns';

export class UvGraphicThemeWidget extends BaseSelectionWidgetFourColumns {
  get name() {
    return 'coverUvGraphicThemeType';
  }

  get label() {
    return this.i18n.gettext('tipo di evento');
  }

  get calculatePrice() {
    return false;
  }

  // propagateClick() {
  //   if (this.clickedItem) {
  //     this.setState({isChosen: true});
  //     this.props.delegate.setConfigurationItem(this.name, this.clickedItem.id, null, true);
  //   }
  // }
}
