import { BaseUvGraphicTextWidget } from '../../core/components/baseAlbumUvGraphicTextsWidget';

export class DebossingTextWidget extends BaseUvGraphicTextWidget {
  get name() {
    return 'debossingTexts';
  }

  get label() {
    return this.i18n.gettext('debossing text');
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    super.UNSAFE_componentWillReceiveProps(nextProps);
    let maxLimit = 0;
    let minLimit = 0;

    if (nextProps.items && nextProps.items.map) {
      nextProps.items.map((item) => {
        maxLimit = item.maxTextLength || 0;
        minLimit = item.minTextLength || 0;
      });
    }

    this.props.delegate.choseDebossingPreviewTexLimit(minLimit, maxLimit);
  }

  async handleFocus(item) {
    //se il testo non è stato digitato dall'utente E non proviene da una configurazione salvata o ereditata lo resetto
    if (!this.props.delegate.isSvgTextTypedByUser() && Object.keys(this.props.value).length === 0) {
      this.props.delegate.choseDebossingPreviewSvgText();
      if (item.rowCode) {
        this.props.delegate.setConfigurationTexts(this.name, item.rowCode, '');
      }
      await this.collectErrors(item, '', item.rowCode);
      this.propagateErrors();
    }
    this.props.delegate.setDebossingPreviewSvg();
  }

  async handleChangeText(item, index, event) {
    this.setState({
      showErrors: true
    });
    let string = event.target.value;
    if (item.contentType === 'NUMBER' && !/^(\s*|\d+)$/.test(string)) {
      return;
    } else {
      if (!/[^\u0000-\u00ff]/g.test(string)) { //eslint-disable-line
        await this.props.delegate.setConfigurationTexts(this.name, index, string);
        await this.collectErrors(item, string, item.rowCode);
      }
    }
    this.propagateErrors();
    this.props.delegate.setDebossingPreviewSvg();
  }
}
