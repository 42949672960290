import { EventBookConfiguration } from './models';

export async function getEventBookConfiguration(configurationId) {
  let configuration = null;

  if (configurationId) {
    configuration = new EventBookConfiguration({ id: configurationId });
    configuration = await configuration.fetch();
  }

  return configuration;
}

export async function getEventBookProductConfigurationPriceParams(filters, configuration) {
  return {
    bindingType: configuration.bindingType,
    formatType: configuration.formatType,
    coverType: configuration.coverType,
    coverUvGraphicType:
      (filters && filters.coverUvGraphicTypes && filters.coverUvGraphicTypes.length > 0) ||
      (!filters && configuration)
        ? configuration.coverUvGraphicType
        : null,
    sheetsNumber: configuration.sheetsNumber,
    paperType:
      (filters && filters.paperTypes && filters.paperTypes.length > 0) ||
      (!filters && configuration)
        ? configuration.paperType
        : null,
    flyleafType:
      (filters && filters.flyleafTypes && filters.flyleafTypes.length > 0) ||
      (!filters && configuration)
        ? configuration.flyleafType
        : null,
    coverPaddingType:
      (filters && filters.coverPaddingTypes && filters.coverPaddingTypes.length > 0) ||
      (!filters && configuration)
        ? configuration.coverPaddingType
        : null,
    debossingType:
      (filters && filters.debossingTypes && filters.debossingTypes.length > 0) ||
      (!filters && configuration)
        ? configuration.debossingType
        : null,
    plaqueType: configuration.plaqueType
  };
}

export async function downloadFile(fileName, file) {
  var element = document.createElement('a');
  element.setAttribute('href', file);
  element.setAttribute('download', fileName);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
