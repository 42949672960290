import { BaseSelectionWidgetFourColumns } from '../../core/components/baseSelectionWidgetFourColumns';

export class OrientationTypesWidget extends BaseSelectionWidgetFourColumns {
  get name() {
    return 'orientationType';
  }

  get label() {
    return this.i18n.gettext('orientamento');
  }

  get hasConfirmModal() {
    return true;
  }

  shouldComponentShowConfirmModal() {
    return (
      this.props.delegate.hasCoverEditorWidget() && this.props.delegate.isCoverEditorProjectValid()
    );
  }

  async onModalConfirmClick() {
    this.props.delegate.resetPhotographicBook();
  }
}
