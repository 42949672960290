import { BaseSelectionWidgetTwoColumns } from '../../core/components/baseSelectionWidgetTwoColumns';

export class DebossingWidget extends BaseSelectionWidgetTwoColumns {
  get name() {
    return 'debossingType';
  }

  get showWidget() {
    return false;
  }

  get label() {
    return this.i18n.gettext('debossing');
  }
}
