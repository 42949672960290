import { BaseSelectionWidgetTwoColumns } from '../../core/components/baseSelectionWidgetTwoColumns';

export class CoverLaminationTypesWidget extends BaseSelectionWidgetTwoColumns {
  get name() {
    return 'coverLaminationType';
  }

  get label() {
    return this.i18n.gettext('plastificazione copertina');
  }
}
