import { BaseColorThreeColumnsWidget } from '../../core/components/baseColorThreeColumnsWidget';

export class CoverColorTypesWidget extends BaseColorThreeColumnsWidget {
  get name() {
    return 'coverColorType';
  }

  get label() {
    return this.i18n.gettext('Colore:');
  }

  get calculatePrice() {
    return false;
  }

  handleMouseOver(item) {
    this.props.delegate.setPreviewImage(item.bookImageUrl);
    this.props.delegate.setPreviewSvgColor(item.uvGraphicColor);
    this.props.delegate.setPreviewSvg();
  }

  handleMouseOut() {
    if (!this.loading) {
      // prevent mouse leave on click
      this.props.delegate.setPreviewSvgColor();
      this.props.delegate.setPreviewSvg();
      this.props.delegate.setPreviewImage();
    }
  }

  handleClick(item) {
    super.handleClick(item);
    this.props.delegate.chosePreviewSvgColor(item.uvGraphicColor);
    this.props.delegate.setPreviewImage(item.bookImageUrl);
  }
}
