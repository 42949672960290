import { routers } from 'outlinejs';
import { routing } from 'outlinejs';

import {
  EventBookController,
  EventBookRestoreOrderController,
  EventBookCreateController,
  EventBookRestoreProjectController
} from './controllers';

export default class extends routers.BaseRouter {
  static get urlPatterns() {
    return {
      '': routing.url('eventbook:main', EventBookController),
      'restore-order/:orderGuid:': routing.url(
        'eventbook:restoreOrder',
        EventBookRestoreOrderController
      ),
      'new/:eventbookType:': routing.url('eventbook:new', EventBookCreateController),
      'edit/:projectId:': routing.url('eventbook:restoreProject', EventBookRestoreProjectController)
    };
  }
}
