import { conf } from 'outlinejs';
import { runtime } from '@outlinejs/contexts';
import { routing } from 'outlinejs';
import axios from 'axios';

import {
  GuiErrors,
  navigateTo,
  mergeQueryParams,
  updateQueryStringParameter,
  promiseRetry,
  GuiNotifications
} from '../core/utils/index';
import Logger from '../core/logger';
import { BaseConfiguratorController } from '../core/controllers';
import { eventBookFunnelSteps } from '../core/utils/breadcrumb';

import { ProfessionalPreOrdersCollection } from '../core/managers';
import { LayoutServicesCodes } from '../services/utils';
import { cleanEventBookConfigurator } from '../core/storage/cleaners';
import { EventbookProjectStorage } from '../core/storage/index';
import { Project } from '../projects/models';

import { EventbookContentView } from './views';
import { BaseLoadingView } from '../core/views';
import {
  EventBookProductCollection,
  EventBookBindingTypesCollection,
  EventBookEventTypesCollection,
  EventBookPaperTypesCollection,
  EventBookOrientationTypesCollection,
  EventBookFormatTypesCollection,
  EventBookPaperLaminationTypesCollection,
  EventBookCoverTypesCollection,
  EventBookCoverLaminationTypesCollection,
  EventBookCoverPaddingCollection,
  EventBookUvGraphicCollection,
  EventBookUvGraphicThemeCollection,
  EventBookUvGraphicElementCollection,
  EventBookUvGraphicTextCollection,
  EventBookPlaqueTypesCollection,
  EventBookPlaqueTextCollection,
  EventBookFlyleafTypeCollection,
  EventBookFlyleafColorMaterialTypeCollection,
  EventBookDecorationTypesCollection,
  EventBookDebossingTypesCollection,
  EventBookDebossingElementFormatTypesCollection,
  EventBookDebossingElementColorTypesCollection,
  EventBookDebossingTextsCollection,
  EventBookPriceCollection,
  EventBookCoverMaterialTypesCollection,
  EventBookCoverColorTypesCollection,
  ConfigurationFileCollection
} from './managers';

import { ConfigurationFile, EventBookConfiguration } from './models';
import { getEventBookProductConfigurationPriceParams, downloadFile } from './utils';
import CoverEditor from './PhotographicCoverEditor';
import { EventBookPackagingConfiguration } from '../eventbook-packaging/models';
import { EventBookPackagingProductCollection } from '../eventbook-packaging/managers';
import EventTracker, { productInterface } from '../core/eventTracker';

/**
 * Controller for /eventbook/new/EVENTBOOK_TYPE url.
 * It manages the initialization of a new configurator for the eventbook.
 * If eventbookType param is not present, the default eventbook configuration is MATERIAL.
 * */
export class EventBookCreateController extends BaseConfiguratorController {
  static get loginRequired() {
    return false;
  }

  get view() {
    return BaseLoadingView;
  }

  get context() {
    return Object.assign(super.context, {
      initViewRendering: this.initViewRendering
    });
  }

  async createEventBookPhotographicWithRetry() {
    return await promiseRetry(
      this.createEventBookPhotographic.bind(this),
      'Create EventBook Photographic configurations'
    );
  }

  async createEventBookPhotographic() {
    return await new EventBookConfiguration().save(
      conf.settings.EVENT_BOOK_PHOTOGRAPHIC_INITIAL_CONFIGURATION
    );
  }

  async createEventBookMaterialWithRetry() {
    return await promiseRetry(
      this.createEventBookMaterial.bind(this),
      'Create EventBook Material configurations'
    );
  }

  async createEventBookMaterial() {
    return await new EventBookConfiguration().save(
      conf.settings.EVENT_BOOK_MATERIAL_INITIAL_CONFIGURATION
    );
  }

  async createEventBookMaterialVelvetWithRetry() {
    return await promiseRetry(
      this.createEventBookMaterialVelvet.bind(this),
      'Create EventBook Material Velvet configurations'
    );
  }

  async createEventBookMaterialVelvet() {
    return await new EventBookConfiguration().save(
      conf.settings.EVENT_BOOK_MATERIAL_VELVET_INITIAL_CONFIGURATION
    );
  }

  async init(eventbookType) {
    this.startInitialRendering();

    if (runtime.isClient) {
      let configuration = null;
      let configurationId = null;

      try {
        // clean local forage data
        await cleanEventBookConfigurator();

        // set configurator
        if (eventbookType && eventbookType.toLowerCase() === 'photographic') {
          // set photographic configuration
          configuration = await this.createEventBookPhotographicWithRetry();
          configurationId = configuration.id;
        } else if (eventbookType && eventbookType.toLowerCase() === 'materialvelvet') {
          configuration = await this.createEventBookMaterialVelvetWithRetry();
          configurationId = configuration.id;
        } else {
          // set default configuration
          configuration = await this.createEventBookMaterialWithRetry();
          configurationId = configuration.id;
        }
      } catch (err) {
        Logger.error('EventBookCreateController.init', {
          error: err,
          eventbookType: eventbookType
        });
      }
      Logger.info(`NEW eventbook configuration ${eventbookType}`, configurationId);
      navigateTo(this.request, this.response, 'eventbook:main', {}, true, { configurationId });
    }
  }
}

/**
 * Controller for /edit/PROJECT_ID url
 * Used to restore project, set local forage and redirect to the eventbook or packaging configurator
 * */
export class EventBookRestoreProjectController extends BaseConfiguratorController {
  get view() {
    return BaseLoadingView;
  }

  get context() {
    return Object.assign(super.context, {
      initViewRendering: this.initViewRendering
    });
  }

  async initClientStorage(project, preOrders) {
    let eventBookConfigurationId = null;
    let eventBookPackagingConfigurationId = null;
    let eventBookPreorderId = null;
    let eventBookPackagingPreorderId = null;

    preOrders.forEach((preOrder) => {
      if (preOrder.productType === 'EventBook') {
        eventBookConfigurationId = preOrder.configurationId;
        eventBookPreorderId = preOrder.id;
      }
      if (preOrder.productType === 'EventBookPackaging') {
        eventBookPackagingConfigurationId = preOrder.configurationId;
        eventBookPackagingPreorderId = preOrder.id;
      }
    });

    // clean configurator data
    await cleanEventBookConfigurator();

    // set new configurator data
    await Promise.all([
      EventbookProjectStorage.setPk(project.id),
      EventbookProjectStorage.setName(project.name)
    ]);

    return {
      configurationId: eventBookConfigurationId,
      packagingConfigurationId: eventBookPackagingConfigurationId,
      preOrderId: eventBookPreorderId,
      packagingPreOrderId: eventBookPackagingPreorderId
    };
  }

  async getProject(projectId) {
    return await new Project({ id: projectId }).fetch();
  }

  async getPreOrders(projectId) {
    let preOrders = await new ProfessionalPreOrdersCollection().filterByProjectId(projectId);

    let eventBookPreOrder = null;
    let eventBookPackagingPreOrder = null;
    preOrders.forEach((preOrder) => {
      if (preOrder.productType === 'EventBook') {
        eventBookPreOrder = preOrder;
      }

      if (preOrder.productType === 'EventBookPackaging') {
        eventBookPackagingPreOrder = preOrder;
      }
    });

    return [eventBookPreOrder, eventBookPackagingPreOrder];
  }

  async redirectToEventBookNew() {
    navigateTo(this.request, this.response, 'eventbook:new', {}, true);
  }

  async init(projectId) {
    await this.initContentProps();

    this.startInitialRendering();

    if (runtime.isClient) {
      let nextState = this.request.query['next-url']
        ? this.request.query['next-url']
        : 'eventbook:main';

      let project = null;
      let preOrders = null;
      let nextStateQueryParams = null;

      try {
        [project, preOrders] = await Promise.all([
          this.getProject(projectId),
          this.getPreOrders(projectId)
        ]);
        nextStateQueryParams = { professionalProjectId: project.id };
      } catch (err) {
        if (err.code === 404) {
          GuiErrors.modalInfo(
            this.i18n.gettext(
              'Il prodotto che stai cercando è stato eliminato. Puoi procedere configurando un nuovo prodotto.'
            ),
            null,
            null,
            null,
            this.redirectToEventBookNew.bind(this)
          );
          return;
        } else {
          Logger.error(
            'EventBookRestoreProjectController.init - failed to fetch project or preorder',
            {
              error: err,
              projectId: projectId,
              project: project,
              preOrders: preOrders
            }
          );
          nextState = conf.settings.HOMEPAGE_BASE_URL;
        }
      }

      if (project && preOrders) {
        try {
          let queryParams = await this.initClientStorage(project, preOrders);
          nextStateQueryParams = mergeQueryParams(nextStateQueryParams, queryParams);
        } catch (err) {
          Logger.error(
            'EventBookRestoreProjectController.init - failed to initialize client storage',
            {
              error: err,
              projectId: projectId,
              project: project,
              preOrders: preOrders
            }
          );
          nextState = conf.settings.HOMEPAGE_BASE_URL;
        }
      }

      navigateTo(this.request, this.response, nextState, {}, true, nextStateQueryParams);
    }
  }
}

/**
 * Controller for /restore-order/ORDER_GUID url
 * Used to restore order, set local forage and redirect to /eventbook/edit/PROJECT_ID
 * If an error occurs redirect to referral url.
 * */
export class EventBookRestoreOrderController extends BaseConfiguratorController {
  get view() {
    return BaseLoadingView;
  }

  get context() {
    return Object.assign(super.context, {
      initViewRendering: this.initViewRendering
    });
  }

  async init(orderGuid) {
    this.startInitialRendering();

    if (runtime.isClient) {
      let nextState = 'eventbook:main';
      let nextStateParams = null;

      if (orderGuid) {
        let projectId = null;

        let preOrders = new ProfessionalPreOrdersCollection();

        await preOrders.filterByOrderGuidAndShopCode(orderGuid, this.customerUser.shopCode);

        if (preOrders.length) {
          projectId = preOrders.first().projectId;

          nextStateParams = { projectId: projectId };
          nextState = routing.Utils.reverse(
            'eventbook:restoreProject',
            this.request,
            nextStateParams
          );

          if (this.request.query['next-url']) {
            nextState = `${nextState}?next-url=${this.request.query['next-url']}`;
          }
        } else {
          Logger.error(
            'EventbookController.EventBookRestoreOrderController - Unable to restore order',
            {
              orderGuid: orderGuid,
              preOrders: preOrders
            }
          );
          nextState = conf.settings.HOMEPAGE_BASE_URL;
        }
      } else {
        Logger.error(
          'EventbookController.EventBookRestoreOrderController - orderGuid not present',
          {
            orderGuid: orderGuid
          }
        );
        nextState = conf.settings.HOMEPAGE_BASE_URL;
      }

      navigateTo(this.request, this.response, nextState, {}, true);
    }
  }
}

/**
 * Main controller for eventbook configurator.
 * If eventBookConfigurationId is present in the local storage, this configuration is restored otherwise
 * a new configurator is initialized.
 * */
export class EventBookController extends BaseConfiguratorController {
  static get loginRequired() {
    return false;
  }

  get productType() {
    return 'EventBook';
  }

  get funnelStep() {
    return 1;
  }

  get funnelSteps() {
    return eventBookFunnelSteps(this.request);
  }

  getCallToAction() {
    return this.i18n.gettext('Procedi');
  }

  getTitle() {
    return this.i18n.gettext('Event Book');
  }

  get context() {
    return Object.assign(super.context, {
      product: this.product, // informazioni sul prodotto
      configuration: this.configuration, // stato dei filtri
      packagingConfiguration: this.packagingConfiguration,
      filters: this.filters, // collection dei filtri
      filtersViewValidity: this.filtersViewValidity,
      priceValidity: this.priceValidity,
      errorsVisibility: this.errorsVisibility,
      loadingCollection: this.loadingCollection,
      initViewRendering: this.initViewRendering,
      loadingPage: this.loadingPage,
      loadingPreview: this.loadingPreview,
      productConfigurationIsLoading: this.productConfigurationIsLoading,
      filtersViewIsLoading: this.filtersViewIsLoading,
      showCoverEditor: this.showCoverEditor,
      coverEditorUrl: this.coverEditorUrl,
      coverEditorProjectIsValid: this.coverEditorProjectIsValid,
      coverEditorController: this.coverEditorController,
      player3dApplicationUrl: this.player3dApplicationUrl,
      coverEditorNotConfigured: this.coverEditorNotConfigured,
      eventTypeSelected: this.eventTypeSelected,
      updatePrice: this.updatePrice,
      unitOfMeasure: this.unitOfMeasure
    });
  }

  get view() {
    return EventbookContentView;
  }

  async initContentProps() {
    super.initContentProps();

    // initialize content view properties
    this.product = {
      productItem: {},
      previewUrl: null,
      previewSvg: null,
      previewSvgUrl: null,
      previewDebossingSvg: null,
      previewDebossingSvgUrl: null,
      previewTexLimit1: null,
      previewTexLimit2: null,
      svgColor: null,
      debossingSvgColor: null,
      chosenSvgColor: null,
      chosenDebossingSvgColor: null,
      productConfiguration: new EventBookConfiguration(),
      price: {},
      showWidget: false,
      showWidgetPrice: this.customerUserIsAuthorized
    };

    this.packagingConfiguration = new EventBookPackagingConfiguration();

    this.showCoverEditor = false;
    this.coverEditorUrl = null;
    this.coverEditorProjectIsValid = true;

    this.player3dApplicationUrl = null;
    this.coverEditorNotConfigured = false;
    this.eventTypeSelected = false;
  }

  async loadInitialConfiguration() {
    let configuration = {};
    let currentConfiguration;
    let configurationId = this.request.query.configurationId;
    if (configurationId === 'null') {
      configurationId = null;
    }

    if (configurationId) {
      Logger.info('RESTORE EventBook ConfigurationId', configurationId);
      currentConfiguration = new EventBookConfiguration({ id: configurationId });

      try {
        let packagingConfigurationId = this.request.query.packagingConfigurationId;
        if (packagingConfigurationId) {
          Logger.info('RESTORE EventBookPackaging Configuration id:', packagingConfigurationId);
          this.packagingConfiguration = new EventBookPackagingConfiguration({
            id: packagingConfigurationId
          });

          [currentConfiguration, this.packagingConfiguration] = await Promise.all([
            currentConfiguration.fetch(),
            this.packagingConfiguration.fetch()
          ]);
        } else {
          currentConfiguration = await currentConfiguration.fetch();
          this.packagingConfiguration = new EventBookPackagingConfiguration();
        }
      } catch (err) {
        if (err.code === 404) {
          GuiErrors.modalInfo(
            this.i18n.gettext(
              'La configurazione che stai cercando è stata eliminata. Puoi procedere configurando un nuovo prodotto.'
            ),
            null,
            null,
            null,
            this.redirectToEventBookNew.bind(this)
          );
        } else {
          Logger.error(
            'EventBookController.loadInitialConfiguration - EventBookConfiguration FETCH',
            {
              error: err,
              id: configurationId
            }
          );
          GuiErrors.modalError(
            this.i18n.gettext(
              'Non è stato possibile caricare la configurazione o il progetto. Ti preghiamo di riprovare.'
            ),
            null,
            null,
            null,
            this.redirectToHomepage.bind(this)
          );
        }
        return null;
      }

      this.product.productConfiguration = currentConfiguration;
      Logger.info('restored configuration', currentConfiguration.toJSON());

      configuration = currentConfiguration.toJSON();

      this.configuration = configuration;
      return configuration;
    } else {
      GuiErrors.modalError(
        this.i18n.gettext(
          'Non è stato possibile caricare la configurazione o il progetto. Ti preghiamo di riprovare.'
        ),
        null,
        null,
        null,
        this.redirectToHomepage.bind(this)
      );
      return null;
    }
  }

  async init() {
    this.startInitialRendering();

    await this.initContentProps();

    if (runtime.isClient) {
      let configuration = await this.loadInitialConfiguration();

      if (configuration) {
        await this.initCoverEditorController();

        this.stopInitialRendering();

        this.reloadView();
      }

      EventTracker.log(
        this.customerUser,
        'configurator_view',
        productInterface(this.product.productConfiguration)
      );
    }
  }

  async initCoverEditorController() {
    this.coverEditorController = new CoverEditor(this.request, this.product.productConfiguration);

    if (
      this.isUserLoggedIn() &&
      this.product.productConfiguration &&
      this.product.productConfiguration.coverEditorProjectId
    ) {
      this.coverEditorController.coverEditorProjectModel =
        await this.coverEditorController.getCoverEditorProject(
          this.product.productConfiguration.coverEditorProjectId
        );
    }
    return this.coverEditorController;
  }

  async redirectToEventBookNew() {
    navigateTo(this.request, this.response, 'eventbook:new', {}, false, null);
  }

  /*
   * Returns true if current configuration is a photographic book
   * */
  hasCoverEditorWidget() {
    return this.configuration && this.configuration.coverType === 'PHOTOGRAPHIC';
  }

  /*
   * Returns true if a cover editor project has been invalidated.
   * This means that only background and images are present when opening again the project.
   * */
  isCoverEditorProjectInitialized() {
    return (
      this.coverEditorController &&
      this.coverEditorController.coverEditorProjectModel &&
      this.coverEditorController.coverEditorProjectModel.init
    );
  }

  /*
   * Returns true if:
   * 1) cover editor project is saved in eventbook configuration
   * 2) cover editor project has a valid cover (production info did not changed)
   * */
  isCoverEditorProjectValid() {
    return (
      this.isCoverEditorProjectSaved() &&
      !this.isCoverEditorProjectInitialized() &&
      this.coverEditorProjectIsValid
    );
  }

  /*
   * Returns true if a cover editor project has been configured and saved
   * */
  isCoverEditorProjectSaved() {
    return (
      this.product.productConfiguration && this.product.productConfiguration.coverEditorProjectId
    );
  }

  isHotBindingSelected() {
    return this.product.productConfiguration.bindingType === 'HOT_BINDING';
  }

  async resetPhotographicBook() {
    Logger.info('RESET photographic project');

    // invalido il widget per notificare l'errore
    this.coverEditorProjectIsValid = false;
    // ricarico i widget
    await this.reloadView();

    this.productConfigurationIsLoading = true;
    this.render(this.context);

    // salvo la nuova configurazione per forzare i nuovi dati produttivi
    // await this.saveProductConfiguration();
    await this.saveConfigurations();

    // eseguo una fetch del progetto per invalidare il modello
    await this.coverEditorController.resetCoverEditorProject();
    // ricarico il player 3D
    await this.refreshPlayer3d();

    this.productConfigurationIsLoading = false;
    this.render(this.context);
  }

  /*
   * Ricarica il player 3D in base alle nuove modifiche
   * */
  async refreshPlayer3d(takeScreenshot = false) {
    if (!this.hasCoverEditorWidget()) {
      return;
    }

    let isCoverEditorConfigured =
      this.user && this.isCoverEditorProjectValid() && this.coverEditorProjectIsValid;

    let player3dParams = await this.coverEditorController.getPlayer3dParams(
      isCoverEditorConfigured,
      this.configuration.orientationType,
      this.configuration.coverLaminationType
    );
    this.player3dApplicationUrl = CoverEditor.getPlayer3dApplicationUrl(
      player3dParams,
      takeScreenshot
    );

    return this.player3dApplicationUrl;
  }

  getPreviewTexts() {
    if (this.configuration.decorationType === conf.settings.EVENT_BOOK_DECORATION_DEBOSSING_TYPE) {
      return this.configuration.debossingTexts;
    }
    return this.configuration.coverUvTexts;
  }

  // OVERRIDE DEFAULT method
  getParamsForCollection(collection) {
    let params = {};
    for (let value of collection.loadParams) {
      /*HACK: the page size could be implicit*/
      if (value === 'pageSize' && !this.configuration[value]) {
        params[value] = 50;
      } else if (value === 'configurationId') {
        params[value] = this.product.productConfiguration.id;
      } else {
        params[value] = this.configuration[value];
      }
    }

    return params;
  }

  // OVERRIDE DEFAULT method
  async loadCollection(collection) {
    this.startCollectionLoading(collection.name);
    let params = this.getParamsForCollection(collection);

    // todo remove from collection
    delete params.coverColorMaterialType;

    return collection
      .loadByParams(params)
      .then((items) => {
        this.filters[collection.name] = items;
        this.setDefaultValueByItems(items);
        this.stopCollectionLoading(collection.name);
      })
      .catch((err) => {
        this.addFilterToErrorCollection(collection.name);
        Logger.error('Loading collection error', {
          error: err,
          params: params,
          module: collection.model.modelName
        });
      });
  }

  async reloadView(getPrice = true) {
    this.productConfigurationIsLoading = true;
    this.filtersViewIsLoading = true;
    if (getPrice) {
      this.updatePrice = false;
    }
    this.render(this.context);

    try {
      this.resetFilterErrorsCollection();

      await Promise.all([
        this.loadCollection(new EventBookBindingTypesCollection()),
        this.loadCollection(new EventBookEventTypesCollection()),
        this.loadCollection(new EventBookPlaqueTypesCollection()),
        this.loadCollection(new EventBookCoverTypesCollection())
      ]);

      await Promise.all([
        this.setSheetsnumberMinMax(),
        this.loadCollection(new EventBookPaperTypesCollection()),
        this.loadCollection(new EventBookPlaqueTextCollection()),
        this.loadCollection(new EventBookOrientationTypesCollection()),
        this.loadCollection(new EventBookFlyleafTypeCollection()),
        this.loadCollection(new EventBookCoverLaminationTypesCollection()),
        this.loadCollection(new EventBookCoverPaddingCollection())
      ]);

      /*
       * Inizializzare la fase di rendering prima dei widget UV, altrimenti non vengono caricati gli SVG
       * */
      await Promise.all([
        this.loadCollection(new EventBookPaperLaminationTypesCollection()),
        this.loadCollection(new EventBookFormatTypesCollection())
      ]);

      await Promise.all([
        this.loadCollection(new EventBookDecorationTypesCollection()),
        this.loadCollection(new EventBookCoverMaterialTypesCollection())
      ]);

      await Promise.all([
        this.loadCollection(new EventBookCoverColorTypesCollection()),
        this.loadCollection(new EventBookUvGraphicCollection()),
        this.loadCollection(new EventBookDebossingTypesCollection())
      ]);

      await Promise.all([
        this.loadCollection(new EventBookFlyleafColorMaterialTypeCollection()),
        this.loadCollection(new EventBookUvGraphicThemeCollection())
      ]);

      await Promise.all([
        this.loadCollection(new EventBookUvGraphicElementCollection()),
        this.loadCollection(new EventBookDebossingElementFormatTypesCollection())
      ]);

      await Promise.all([
        this.loadCollection(new EventBookUvGraphicTextCollection()),
        this.loadCollection(new EventBookDebossingTextsCollection()),
        this.loadCollection(new EventBookDebossingElementColorTypesCollection())
      ]);

      /*
       * DEVO RICARICARE IL PRODOTTO PER AVERE il campo previewBookCode sempre aggiornato
       * */
      await this.loadProduct();
      /* --- */
    } catch (err) {
      Logger.error('EventBookController.reloadView-loadFilters', {
        error: err,
        configuration: this.configuration,
        productConfiguration: this.product.productConfiguration,
        filters: this.filters
      });
    } finally {
      this.filtersViewIsLoading = false;
      this.render(this.context);
    }

    this.getPriceForCurrentConfiguration(getPrice);
  }

  async getPriceForCurrentConfiguration(getPrice) {
    if (this.filters === []) {
      let timestamp = new Date().getTime();
      Logger.error('EventBookController.reloadView-emptyfilters', {
        error: 'Empty filters',
        errorCode: timestamp,
        configuration: this.configuration,
        productConfiguration: this.product.productConfiguration,
        filters: this.filters
      });
      this.productConfigurationIsLoading = false;
      this.render(this.context);
    } else {
      try {
        this.loadProduct();
        if (getPrice) {
          this.loadPrice();
        }
      } catch (err) {
        Logger.error('EventBookController.reloadView-LoadProduct', {
          error: err,
          configuration: this.configuration,
          productConfiguration: this.product.productConfiguration,
          filters: this.filters
        });
      } finally {
        this.productConfigurationIsLoading = false;
        this.updatePrice = true;
        this.render(this.context);
      }
    }
  }

  async loadProduct() {
    let productParams = {
      bindingType: this.configuration.bindingType,
      eventType: this.configuration.eventType,
      formatType: this.configuration.formatType,
      coverType: this.configuration.coverType,
      coverLaminationType:
        this.filters.coverLaminationTypes && this.filters.coverLaminationTypes.length > 0
          ? this.configuration.coverLaminationType
          : null,
      coverPaddingType:
        this.filters.coverPaddingTypes && this.filters.coverPaddingTypes.length > 0
          ? this.configuration.coverPaddingType
          : null,
      // coverColorMaterialType: this.filters.coverColorMaterialTypes && this.filters.coverColorMaterialTypes.length > 0 ? this.configuration.coverColorMaterialType : null,
      coverMaterialType:
        this.filters.coverMaterialTypes && this.filters.coverMaterialTypes.length > 0
          ? this.configuration.coverMaterialType
          : null,
      coverColorType:
        this.filters.coverColorTypes && this.filters.coverColorTypes.length > 0
          ? this.configuration.coverColorType
          : null,
      coverUvGraphicType:
        this.filters.coverUvGraphicTypes && this.filters.coverUvGraphicTypes.length > 0
          ? this.configuration.coverUvGraphicType
          : null,
      coverUvGraphicThemeType:
        this.filters.coverUvGraphicThemeTypes && this.filters.coverUvGraphicThemeTypes.length > 0
          ? this.configuration.coverUvGraphicThemeType
          : null,
      coverUvGraphicElementType:
        this.filters.coverUvGraphicElementTypes &&
        this.filters.coverUvGraphicElementTypes.length > 0
          ? this.configuration.coverUvGraphicElementType
          : null,
      debossingType:
        this.filters.debossingTypes && this.filters.debossingTypes.length > 0
          ? this.configuration.debossingType
          : null,
      debossingElementFormatType:
        this.filters.debossingElementFormatTypes &&
        this.filters.debossingElementFormatTypes.length > 0
          ? this.configuration.debossingElementFormatType
          : null,
      debossingElementColorType:
        this.filters.debossingElementColorTypes &&
        this.filters.debossingElementColorTypes.length > 0
          ? this.configuration.debossingElementColorType
          : null
    };

    let product = await this.getProduct(new EventBookProductCollection(), productParams);
    if (product) {
      this.setConfigurationProduct(product);
    } else {
      Logger.error('EventBook Product must be defined', {
        productParams: productParams,
        configuration: this.configuration,
        productConfiguration: this.product.productConfiguration,
        filters: this.filters
      });
    }
  }

  async loadPrice() {
    let priceParams = await getEventBookProductConfigurationPriceParams(
      this.filters,
      this.configuration
    );
    priceParams = Object.assign(priceParams, {
      language: this.getLanguage(),
      shopCode: this.customerUserIsAuthorized ? this.customerUser.shopCode : null
    });

    let price = await this.getPrice(new EventBookPriceCollection(), priceParams);
    if (price) {
      this.setConfigurationPrice(price);
    }
  }

  async setConfigurationProduct(productItem) {
    super.setConfigurationProduct(productItem);
    if (this.configuration.decorationType === conf.settings.EVENT_BOOK_DECORATION_DEBOSSING_TYPE) {
      await this.setDebossingPreviewSvg();
    } else {
      await this.setPreviewSvg();
    }
    await this.refreshPlayer3d();
    this.render(this.context);
  }

  setSheetsnumberMinMax() {
    if (this.filters.bindingTypes) {
      this.filters.bindingTypes.each((item) => {
        if (item.id === this.configuration.bindingType) {
          this.filters.sheetsNumber = {
            min: item.minSpreadsNumber,
            max: item.maxSpreadsNumber
          };
        }
      });
    }
  }

  async saveProductConfiguration(currentConfiguration) {
    return await this.product.productConfiguration.save(currentConfiguration);
  }

  async saveProductConfigurationWithRetry(currentConfiguration) {
    return await promiseRetry(
      this.saveProductConfiguration.bind(this, currentConfiguration),
      'Save product configurations',
      { currentConfiguration: currentConfiguration }
    );
  }

  async saveConfiguration() {
    try {
      // new save
      let currentConfiguration;
      let shopcode = null;
      let sheets = null;
      let previewBookCode = null;
      let coverEditorProjectId = null;

      if (this.customerUserIsAuthorized) {
        shopcode = this.customerUser.shopCode;
      }
      if (this.configuration && this.configuration.sheetsNumber) {
        sheets = this.configuration.sheetsNumber * 2;
      }
      if (this.product && this.product.productItem) {
        previewBookCode = this.product.productItem.id;
      }
      if (this.product && this.product.productConfiguration.coverEditorProjectId) {
        coverEditorProjectId = this.product.productConfiguration.coverEditorProjectId;
      }

      if (!currentConfiguration) {
        currentConfiguration = this.configuration;
      }

      // delete cover uv texts if graphic UV is not selected
      if (!this.filters.coverUvTexts || (this.filters.coverUvTexts && this.filters.coverUvTexts.length === 0)) { //eslint-disable-line
        currentConfiguration.coverUvTexts = {};
      }

      // delete debossing uv texts if debossing is not selected
      if (!this.filters.debossingTextsCollection || (this.filters.debossingTextsCollection && this.filters.debossingTextsCollection.length === 0)) { //eslint-disable-line
        currentConfiguration.debossingTexts = {};
      }

      currentConfiguration.shopCode = shopcode;
      currentConfiguration.pagesNumber = sheets;
      currentConfiguration.previewBookCode = previewBookCode;
      currentConfiguration.coverEditorProjectId = coverEditorProjectId;
      // end new save

      this.coverEditorNotConfigured = false;

      let configuration = await this.saveProductConfigurationWithRetry(currentConfiguration);
      Logger.info('Event Book Configuration id:', this.product.productConfiguration.id);
      return configuration;
    } catch (err) {
      let timestamp = new Date().getTime();
      Logger.error('EventBookController.saveConfiguration', {
        error: err,
        errorCode: timestamp,
        productConfiguration: this.product.productConfiguration,
        configuration: this.configuration
      });
      return null;
    }
  }

  async savePackagingConfiguration(eventbookConfiguration) {
    let packagingConfiguration;

    if (this.packagingConfiguration && this.packagingConfiguration.id) {
      Logger.info('savePackagingConfiguration: ', this.packagingConfiguration.id);
      packagingConfiguration = this.packagingConfiguration;
    } else {
      Logger.info('NEW EventBookPackaging Configuration');

      // default packaging configuration
      packagingConfiguration = new EventBookPackagingConfiguration();
      packagingConfiguration.packagingType =
        conf.settings.EVENT_BOOK_PACKAGING_INITIAL_CONFIGURATION.packagingType;
      packagingConfiguration.packagingDecorationType =
        conf.settings.EVENT_BOOK_PACKAGING_INITIAL_CONFIGURATION.packagingDecorationType;
      packagingConfiguration.packagingUvGraphicType =
        conf.settings.EVENT_BOOK_PACKAGING_INITIAL_CONFIGURATION.packagingUvGraphicType;
      packagingConfiguration.packagingUvGraphicThemeType =
        conf.settings.EVENT_BOOK_PACKAGING_INITIAL_CONFIGURATION.packagingUvGraphicThemeType;
      packagingConfiguration.packagingUvGraphicElementType =
        conf.settings.EVENT_BOOK_PACKAGING_INITIAL_CONFIGURATION.packagingUvGraphicElementType;
      packagingConfiguration.packagingDebossingType =
        conf.settings.EVENT_BOOK_PACKAGING_INITIAL_CONFIGURATION.packagingDebossingType;
      packagingConfiguration.packagingDebossingElementType =
        conf.settings.EVENT_BOOK_PACKAGING_INITIAL_CONFIGURATION.packagingDebossingElementType;
      packagingConfiguration.packagingDebossingElementFormatType =
        conf.settings.EVENT_BOOK_PACKAGING_INITIAL_CONFIGURATION.packagingDebossingElementFormatType;
      packagingConfiguration.packagingDebossingElementColorType =
        conf.settings.EVENT_BOOK_PACKAGING_INITIAL_CONFIGURATION.packagingDebossingElementColorType;
      packagingConfiguration.packagingUvTexts = {};
      packagingConfiguration.packagingDebossingTexts = {};

      if (eventbookConfiguration.coverType === 'PHOTOGRAPHIC') {
        packagingConfiguration.packagingMaterialType = 'MATERIAL_RAFFAELLO';
        packagingConfiguration.packagingColorType = 'COLOR_WHITE';
      } else {
        // default packaging for Event Book MATERIAL
        if (eventbookConfiguration.coverType !== 'MATERIAL') {
          Logger.error(
            'EventbookPackagingController.loadDefaultConfiguration - Invalid coverType',
            {
              eventBookConfiguration: eventbookConfiguration,
              packagingConfiguration: packagingConfiguration
            }
          );
        }

        // packagingConfiguration.packagingDecorationType = eventbookConfiguration.decorationType;
        packagingConfiguration.packagingUvGraphicType =
          eventbookConfiguration.coverUvGraphicType.replace('UV_', '');
        packagingConfiguration.packagingUvGraphicThemeType =
          eventbookConfiguration.coverUvGraphicThemeType;
        packagingConfiguration.packagingUvGraphicElementType =
          eventbookConfiguration.coverUvGraphicElementType;
        packagingConfiguration.packagingUvTexts = eventbookConfiguration.coverUvTexts;
        packagingConfiguration.packagingDebossingTexts = eventbookConfiguration.debossingTexts;
        packagingConfiguration.packagingDebossingType = eventbookConfiguration.debossingType;
        packagingConfiguration.packagingDebossingElementType =
          eventbookConfiguration.debossingElementType;
        packagingConfiguration.packagingDebossingElementFormatType =
          eventbookConfiguration.debossingElementFormatType;
        packagingConfiguration.packagingDebossingElementColorType =
          eventbookConfiguration.debossingElementColorType;
        // packagingConfiguration.packagingColorMaterialType = eventbookConfiguration.coverColorMaterialType.replace('COVER_', '');
        packagingConfiguration.packagingMaterialType =
          eventbookConfiguration.coverMaterialType.replace('COVER_', '');
        packagingConfiguration.packagingColorType = eventbookConfiguration.coverColorType.replace(
          'COVER_',
          ''
        );
      }
    }

    // define if update or create is necessary (new configuration or format type is changed or user logged in)
    if (!packagingConfiguration.formatType || (packagingConfiguration.formatType !== eventbookConfiguration.formatType) || (packagingConfiguration.shopCode !== eventbookConfiguration.shopCode)) { //eslint-disable-line
      Logger.info('Update/Create EventBookPackaging Configuration');
      // patch format Type
      packagingConfiguration.formatType = eventbookConfiguration.formatType;

      let collection = new EventBookPackagingProductCollection();
      let params = {
        formatType: packagingConfiguration.formatType,
        // packagingColorMaterialType: packagingConfiguration.packagingColorMaterialType,
        packagingMaterialType: packagingConfiguration.packagingMaterialType,
        packagingColorType: packagingConfiguration.packagingColorType,
        packagingType: packagingConfiguration.packagingType,
        packagingUvGraphicType: packagingConfiguration.packagingUvGraphicType,
        packagingUvGraphicThemeType: packagingConfiguration.packagingUvGraphicThemeType,
        packagingUvGraphicElementType: packagingConfiguration.packagingUvGraphicElementType,
        packagingDebossingType: packagingConfiguration.packagingDebossingType,
        packagingDebossingElementFormatType:
          packagingConfiguration.packagingDebossingElementFormatType,
        packagingDebossingElementColorType:
          packagingConfiguration.packagingDebossingElementColorType
      };
      let items = await collection.loadByParams(params);
      let productItem = items.first();

      // if product is ok, we save solid configuration.
      if (!productItem) {
        Logger.error('savePackagingConfiguration - No product configuration', {
          params: params,
          items: items
        });
        return null;
      }

      // save packaging configuration
      packagingConfiguration.shopCode = this.customerUser ? this.customerUser.shopCode : null;
      packagingConfiguration.previewPackagingCode = productItem.id;

      try {
        packagingConfiguration = await packagingConfiguration.save();
      } catch (err) {
        let timestamp = new Date().getTime();
        Logger.error('EventBookController.savePackagingConfiguration', {
          error: err,
          errorCode: timestamp,
          packagingConfiguration: packagingConfiguration,
          configuration: this.configuration
        });
        return null;
      }
    }

    return packagingConfiguration;
  }

  async saveConfigurations() {
    let hasError = false;
    let savedConfiguration = await this.saveConfiguration();
    if (savedConfiguration) {
      this.product.productConfiguration = savedConfiguration;
    } else {
      hasError = true;
    }

    if (!hasError) {
      this.packagingConfiguration = await this.savePackagingConfiguration(
        this.product.productConfiguration
      );

      if (!this.packagingConfiguration) {
        hasError = true;
      }
    }

    if (hasError) {
      GuiErrors.modalError(
        this.i18n.gettext(
          'Non è stato possibile salvare la configurazione, si prega di ripetere la procedura.'
        ),
        'Error detail: saveConfigurations'
      );
      return [null, null];
    }

    return [this.product.productConfiguration, this.packagingConfiguration];
  }

  async validateWidgetErrors() {
    /*
     * Validate cover editor errors
     */
    if (
      (this.hasCoverEditorWidget() && !this.product.productConfiguration.coverEditorProjectId) ||
      (this.hasCoverEditorWidget() && !this.isCoverEditorProjectValid())
    ) {
      this.coverEditorNotConfigured = true;
      this.stopLoadingPage();
      this.viewInstance.scrollToFirstWidgetError();
      return;
    }
  }

  async goToNextStep() {
    this.errorsVisibility = true;
    this.startLoadingPage();

    await this.validateWidgetErrors();

    if (await this.checkWidgetErrors()) {
      // save the configuration before
      // let configuration = await this.saveProductConfiguration();
      let [configuration, packagingConfiguration] = await this.saveConfigurations(); //eslint-disable-line
      if (configuration) {
        /*
         * PATCH
         * un album fotografico con più di 90 pagine (45 fogli) in hotbinding con la copertina imbottita non sta nella scatola e non può essere ordinato*/
        if (
          configuration.pagesNumber > 90 &&
          configuration.bindingType === 'HOT_BINDING' &&
          configuration.coverType === 'PHOTOGRAPHIC' &&
          configuration.coverPaddingType === 'PADDING_YES'
        ) {
          this.stopLoadingPage();
          GuiErrors.modalError(
            this.i18n.gettext(
              'Questa specifica configurazione non può essere ordinata: diminuisci il numero di foglio oppure cammbia il tipo di rilegatura o copertina'
            )
          );
          return false;
        }

        // PATCH browser history to prevent mupltiple packaging creation
        if (!this.request.query.packagingConfigurationId) {
          try {
            let destinationUrl = routing.Utils.reverse('eventbook:main', this.request);
            let queryParams = mergeQueryParams(this.request.query, {
              packagingConfigurationId: String(packagingConfiguration.id)
            });
            for (let queryParam in queryParams) {
              destinationUrl = updateQueryStringParameter(
                destinationUrl,
                queryParam,
                queryParams[queryParam]
              );
            }
            history.replaceState(null, null, destinationUrl);
          } catch (err) {
            Logger.error('history.replaceState', { error: err });
          }
        }

        navigateTo(
          this.request,
          this.response,
          'eventbook-packaging:main',
          {},
          false,
          mergeQueryParams(this.request.query, {
            configurationId: this.product.productConfiguration.id,
            packagingConfigurationId: this.packagingConfiguration.id
          })
        );
      }
    }
    this.stopLoadingPage();
  }

  /*
   * Rasterize SVG for player 3D
   * */
  async rasterizeCoverSvg(projectId, coverId) {
    this.productConfigurationIsLoading = true;
    try {
      // valido il widget per eliminare l'errore
      this.coverEditorProjectIsValid = true;
      this.loadingPreview = true;
      this.render(this.context);

      // save cover editor project id into eventbook configuration
      this.product.productConfiguration.coverEditorProjectId = projectId;

      await this.saveConfigurations(); //eslint-disable-line

      // syncronize model with last save
      await this.coverEditorController.syncCoverEditorProject();
      // rasterizzo immagine
      await this.coverEditorController.rasterizeCover(this.product.productConfiguration.id);

      if (!this.coverEditorController.rasterizationSuccess) {
        let timestamp = new Date().getTime();
        this.productConfigurationIsLoading = false;
        Logger.error('EventBookController.rasterizeCoverSvg - Rasterization Error', {
          errorCode: timestamp,
          projectId: projectId,
          coverId: coverId,
          rasterizationStatus: this.coverEditorController.rasterizationStatus,
          productConfiguration: this.product.productConfiguration
        });
        GuiErrors.modalError(
          this.i18n.gettext(
            'Non è stato possibile salvare la copertina fotografica, si prega di ripetere la procedura.'
          ),
          'Error detail: rasterizeCoverSvg - code: ' + timestamp
        );
      }
      await this.refreshPlayer3d(true);
    } catch (err) {
      this.productConfigurationIsLoading = true;
      let timestamp = new Date().getTime();
      Logger.error('EventBookController.rasterizeCoverSvg', {
        error: err,
        errorCode: timestamp,
        projectId: projectId,
        coverId: coverId,
        rasterizationStatus: this.coverEditorController.rasterizationStatus
      });
      GuiErrors.modalError(
        this.i18n.gettext(
          'Non è stato possibile salvare la copertina fotografica, si prega di ripetere la procedura.'
        ),
        'Error detail: rasterizeCoverSvg - code: ' + timestamp
      );
    } finally {
      this.loadingPreview = false;
      this.productConfigurationIsLoading = false;
      this.render(this.context);
    }
  }

  async uploadCoverPreviewImage(image) {
    let configurationFileList = await new ConfigurationFileCollection().filterByConfigurationId(
      this.product.productConfiguration.id,
      conf.settings.EVENT_BOOK_PHOTOGRAPHIC_CART_PREVIEW_SVG_FILE_TYPE
    );
    if (configurationFileList && configurationFileList.length !== 0) {
      let promises = [];
      configurationFileList.toArray().forEach((configurationFileModel) => {
        promises.push(configurationFileModel.destroy());
      });
      await Promise.all(promises);
    }

    const fileName = `${new Date().getTime()}_cover_preview.png`;
    const configurationFileData = {
      configurationId: this.product.productConfiguration.id,
      fileType: conf.settings.EVENT_BOOK_PHOTOGRAPHIC_CART_PREVIEW_SVG_FILE_TYPE, //eslint-disable-line
      fileName: fileName,
      legacy: true // this flag is used to ensure upload with UP
    };

    let configurationFile = await new ConfigurationFile().save(configurationFileData);
    const uploadUrl = configurationFile.uploadUrl;

    try {
      await axios.post(uploadUrl, image);
    } catch (e) {
      Logger.error('EventBookController.uploadCoverPreviewImage - failed to upload', {
        error: e,
        uploadUrl: uploadUrl,
        configurationFile: configurationFile
      });
      this.productConfigurationIsLoading = false;
      this.render(this.context);
      return null;
    }

    try {
      await configurationFile.save({ uploaded: true });
    } catch (e) {
      Logger.error(
        'EventBookController.uploadCoverPreviewImage - failed to update ConfigurationFile',
        {
          error: e,
          uploadUrl: uploadUrl,
          configurationFile: configurationFile
        }
      );
    }

    this.productConfigurationIsLoading = false;
    this.render(this.context);
  }

  async saveCoverPreview(image) {
    this.productConfigurationIsLoading = true;
    this.render(this.context);
    await this.uploadCoverPreviewImage(image);
    Logger.info('Save image for cart', 'DONE');
    this.productConfigurationIsLoading = false;
    this.render(this.context);
  }

  async showCoverEditorIframe() {
    this.startLoadingPage();

    // log in user
    if (!this.isUserLoggedIn()) {
      this.redirectToLoginPage();
      return;
    }

    // alert for the users without shopcode
    if (!this.customerUserIsAuthorized) {
      GuiErrors.modalInfo(this.i18n.gettext("Non hai permessi per aprire l'editor"));
      this.stopLoadingPage();
      return;
    }

    // controllo se sono presenti errori
    if (!this.filtersViewValidity && this.viewInstance.isCoverEditorWidgetBlocked()) {
      this.viewInstance.scrollToFirstWidgetErrorExceptCoverEditor();
      this.stopLoadingPage();
      return;
    }

    // save configuration
    // let configuration = await this.saveProductConfiguration();
    let [configuration, packagingConfiguration] = await this.saveConfigurations(); //eslint-disable-line
    if (!configuration) {
      this.stopLoadingPage();
      return;
    }

    try {
      if (!this.isCoverEditorProjectSaved()) {
        // creo un progetto nuovo AeCoverEditor
        Logger.info('Create new project');
        await this.coverEditorController.createCoverEditorProject();
      }
    } catch (err) {
      this.stopLoadingPage();
      let timestamp = new Date().getTime();
      GuiErrors.modalError(
        this.i18n.gettext('Non è stato possibile aprire cover editor'),
        'Error detail: createCoverEditorProject - code: ' + timestamp
      );
      Logger.error('EventBook.createCoverEditorProject', {
        error: err,
        errorCode: timestamp,
        configuration: this.configuration
      });
      return false;
    }

    this.coverEditorUrl = await this.coverEditorController.getCoverEditorApplicationUrl();
    this.stopLoadingPage();

    this.showCoverEditor = this.coverEditorUrl !== null;

    this.render(this.context);
  }

  hideCoverEditorIframe() {
    this.showCoverEditor = false;
    this.render(this.context);
  }

  downloadProductTemplate() {
    this.downloadTemplateFromPlayer3d();
  }

  async downloadTemplateFromPlayer3d() {
    this.startLoadingPage();

    await this.saveConfigurations();

    let productConfiguration = await this.coverEditorController.getPlayer3dParamsForConfiguration(
      this.product.productConfiguration.id
    );

    this.stopLoadingPage();

    let receiverWrapper = document.getElementById('player3dIframe');
    let receiver = receiverWrapper.getElementsByTagName('iframe')[0];
    let TARGET_ORIGIN = conf.settings.COVER_3D_PLAYER_APPLICATION_URL;
    receiver.contentWindow.postMessage(
      { message: 'Player3dExportTemplate', data: { productConfiguration } },
      TARGET_ORIGIN
    );
  }

  showDownloadTemplateDialog() {
    GuiNotifications.modalConfirmAction(
      this.i18n.gettext(
        "Scarica il template se desideri impaginare la copertina con un tuo software (es. Photoshop, versione CC 2013 o più recente). Il file JPG ha un livello di trasparenza che illustra le guide. Posiziona le immagini sotto questo livello e ricordati di eliminarlo prima di salvare il file JPG per la stampa. Una volta completata la copertina, caricala sul nostro editor e verificane l'anteprima nel player 3D. Ricordati che ogni configurazione ha il suo specifico template da scaricare."
      ),
      null,
      this.i18n.gettext('Ok'),
      this.i18n.gettext('Cancel'),
      this.downloadProductTemplate.bind(this),
      null
    );
  }

  saveCoverTemplate(file, fileExtension) {
    downloadFile(`cover-template.${fileExtension}`, file);
  }

  getdisabledWidgets() {
    let disabledList = [];
    let code = this.product.productConfiguration.serviceConfigurationTypeCode;
    if (code === LayoutServicesCodes.aeStudioCode) {
      disabledList = ['paperType', 'sheetsNumber', 'bindingType', 'formatType', 'orientationType'];
    } else if (code === LayoutServicesCodes.aeVeloceCode) {
      disabledList = ['coverType', 'coverEditor', 'sheetsNumber', 'orientationType'];
    } else if (code === LayoutServicesCodes.readyToPrintCode) {
      disabledList = ['coverType', 'coverEditor', 'orientationType'];
    } else if (code === LayoutServicesCodes.fullServiceDesignCode) {
      disabledList = ['coverType', 'coverEditor', 'sheetsNumber', 'orientationType'];
    }
    return disabledList;
  }

  redirectToHomepage() {
    navigateTo(this.request, this.response, conf.settings.HOMEPAGE_BASE_URL, {}, true);
  }
}
