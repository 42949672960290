import { BaseDebossingElementColorsWidget } from '../../core/components/baseDebossingElementColorsWidget';

export class DebossingElementColorsWidget extends BaseDebossingElementColorsWidget {
  get name() {
    return 'debossingElementColorType';
  }

  get label() {
    return this.i18n.gettext('debossing available colors');
  }

  get configurationDebossingElementColorType() {
    return this.props.delegate.configuration.debossingElementColorType;
  }
}
