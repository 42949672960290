import { BaseUvGraphicTextWidget } from '../../core/components/baseUvGraphicTextWidget';

export class UvGraphicTextWidget extends BaseUvGraphicTextWidget {
  get name() {
    return 'coverUvTexts';
  }

  get label() {
    return this.i18n.gettext('testo uv');
  }
}
