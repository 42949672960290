import { conf } from 'outlinejs';
import axios from 'axios';

import CoverEditorBaseController from '../core/coverEditorBaseController';

import { PhotographicBookCover, AeCoverEditorProject, ConfigurationFile } from './models';
import { ConfigurationFileCollection, EventBookCoverProductionInfoCollection } from './managers';
import { convertSvgContent, convertSvgToPng } from '@photosi/svg-converter-js';
import Logger from '../core/logger';

/*
 * Class handler for Cover Editor Project & Player 3D
 * */
export default class extends CoverEditorBaseController {
  constructor(...args) {
    super(...args);

    this.coverEditorProjectClass = AeCoverEditorProject;
    this.applicationBaseUrl = conf.settings.COVER_EDITOR_APPLICATION_URL;

    this.rasterizedImageUrl = null;
    this._rasterizedImageUrl = null;
    this._rasterizationStatus = null;
  }

  get rasterizationStatus() {
    return this._rasterizationStatus;
  }

  get rasterizationSuccess() {
    return this._rasterizationStatus === 'LOADED';
  }

  async getRasterizedImageUrl() {
    if (this._rasterizedImageUrl) {
      return this._rasterizedImageUrl;
    }

    let configurationFileList = await new ConfigurationFileCollection().filterByConfigurationId(
      this.productConfigurationModel.id,
      conf.settings.EVENT_BOOK_PHOTOGRAPHIC_PLAYER_3D_SVG_FILE_TYPE
    );
    if (configurationFileList.models.length === 0) {
      return null;
    }

    return configurationFileList.models[configurationFileList.models.length - 1].fileUrl;
  }

  async getCover(id = this.coverEditorProjectModel.coverId) {
    return await new PhotographicBookCover({ id: id }).fetch();
  }

  /*
   * Rasterize cover uses rasterize method od product backbone model
   * */
  async rasterizeCover(productConfigurationId) {
    let cover = await this.getCover();
    const svgContent = await convertSvgContent(cover.svgContent);
    const fileBlob = await convertSvgToPng(svgContent);
    let uploadedData = await this.uploadCoverPng(fileBlob, productConfigurationId);

    this._rasterizedImageUrl = decodeURIComponent(uploadedData.fileUrl);
    this._rasterizationStatus = 'LOADED';
  }

  async uploadCoverPng(blob, productConfigurationId) {
    let configurationFileList = await new ConfigurationFileCollection().filterByConfigurationId(
      productConfigurationId,
      conf.settings.EVENT_BOOK_PHOTOGRAPHIC_PLAYER_3D_SVG_FILE_TYPE
    );
    if (configurationFileList && configurationFileList.length !== 0) {
      let promises = [];
      configurationFileList.toArray().forEach((configurationFileModel) => {
        promises.push(configurationFileModel.destroy());
      });
      await Promise.all(promises);
    }

    const fileName = `plain_3d_cover_${new Date().getTime()}.png`;
    const configurationFileData = {
      configurationId: productConfigurationId,
      fileType: conf.settings.EVENT_BOOK_PHOTOGRAPHIC_PLAYER_3D_SVG_FILE_TYPE,
      fileName: fileName,
      legacy: true // this flag is used to ensure upload with UP
    };
    let configurationFile = await new ConfigurationFile().save(configurationFileData);
    const uploadUrl = configurationFile.uploadUrl;

    try {
      await axios.post(uploadUrl, blob);
    } catch (e) {
      Logger.error('CoverEditorBaseController.uploadCoverPng - failed to upload', {
        error: e,
        uploadUrl: uploadUrl,
        configurationFile: configurationFile
      });
      return null;
    }

    try {
      await configurationFile.save({ uploaded: true });
    } catch (e) {
      Logger.error(
        'CoverEditorBaseController.uploadCoverSvg - failed to update ConfigurationFile',
        {
          error: e,
          uploadUrl: uploadUrl,
          configurationFile: configurationFile
        }
      );
    }

    return {
      fileUrl: configurationFile.fileUrl
    };
  }

  getDefaultCoverConfiguration(orientationType) {
    return conf.settings.PHOTOGRAPHIC_BOOK_COVER_DEFAULT_CONFIGURATIONS.find((configuration) => {
      return configuration.id === orientationType;
    });
  }

  async getCoverProductionInfoForConfiguration(productConfigurationId) {
    let params = { currentPage: 1, pageSize: 1, configurationId: productConfigurationId };
    let coverProductionInfo = await new EventBookCoverProductionInfoCollection().fetch({
      data: params
    });
    return coverProductionInfo.first();
  }

  async getPlayer3dParams(
    isCoverEditorConfigured,
    productConfigurationOrientationType,
    productConfigurationCoverLaminationType
  ) {
    let coverConf;
    let rasterizedImageUrl;

    if (isCoverEditorConfigured) {
      [coverConf, rasterizedImageUrl] = await Promise.all([
        this.getCover(),
        this.getRasterizedImageUrl()
      ]);
      coverConf.urlRasterizedImage = rasterizedImageUrl;
    } else {
      coverConf = this.getDefaultCoverConfiguration(productConfigurationOrientationType);
    }
    coverConf.paperType = productConfigurationCoverLaminationType;
    return coverConf;
  }

  async getPlayer3dParamsForConfiguration(productConfigurationId) {
    let coverProductionInfo = await this.getCoverProductionInfoForConfiguration(
      productConfigurationId
    );
    return {
      urlRasterizedImage:
        'https://file.photosi.com/aerepo/copertine/event_book/image/Fotografico_V.jpg',
      cardBoardWidth: coverProductionInfo.cardBoardWidth,
      cardBoardHeight: coverProductionInfo.cardBoardHeight,
      spineWidth: coverProductionInfo.spineWidth,
      channelWidth: coverProductionInfo.channelWidth,
      borderThickness: coverProductionInfo.borderThickness,
      paperType: coverProductionInfo.paperType,
      padding: coverProductionInfo.padding
    };
  }

  static getPlayer3dApplicationUrl(coverConfiguration, takeScreenshot) {
    let extraParam = takeScreenshot ? `&screenshot=true&timestamp=${new Date().getTime()}` : '';
    return `${conf.settings.COVER_3D_PLAYER_APPLICATION_URL}
?coverImageUrl=${coverConfiguration.urlRasterizedImage}
&cardBoardWidth=${coverConfiguration.cardBoardWidth}
&cardBoardHeight=${coverConfiguration.cardBoardHeight}
&spineWidth=${coverConfiguration.spineWidth}
&channelWidth=${coverConfiguration.channelWidth}
&borderThickness=${coverConfiguration.borderThickness}
&paperType=${coverConfiguration.paperType}
&padding=${coverConfiguration.padding}
&unit=point
${extraParam}`;
  }
}
