import { BaseCollection } from '../core/managers';
import { LoadableCollection } from '../core/managers';
import { conf } from 'outlinejs';

import {
  EventBookProduct,
  EventBookPrice,
  EventBookBindingType,
  EventBookEventType,
  EventBookPaperType,
  EventBookPaperLaminationType,
  EventBookOrientationType,
  EventBookFormatType,
  EventBookCoverType,
  EventBookCoverLaminationType,
  EventBookCoverColorMaterialType,
  EventBookCoverPaddingType,
  EventBookUvGraphicType,
  EventBookUvGraphicThemeType,
  EventBookUvGraphicElementType,
  EventBookUvGraphicText,
  EventBookFlyleafType,
  EventBookFlyleafColorMaterialType,
  EventBookPlaqueType,
  EventBookPlaqueText,
  EventBookDebossingTextType,
  EventBookDebossingElementColorType,
  EventBookDebossingElementFormatType,
  EventBookDebossingType,
  EventBookDecorationType,
  EventBookRasterize,
  EventBookCoverMaterialType,
  EventBookCoverColorType,
  EventBookCoverProductionInfo,
  ConfigurationFile
} from './models';

export class EventBookProductCollection extends LoadableCollection {
  get name() {
    return 'eventBookProducts';
  }

  get url() {
    return conf.settings.EVENT_BOOK_PRODUCTS_URL;
  }

  get model() {
    return EventBookProduct;
  }
}

export class EventBookPriceCollection extends LoadableCollection {
  get name() {
    return 'eventBookPrices';
  }

  get url() {
    return conf.settings.EVENT_BOOK_PRICES_URL;
  }

  get model() {
    return EventBookPrice;
  }
}

export class EventBookBindingTypesCollection extends LoadableCollection {
  get name() {
    return 'bindingTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_COVER_BINDING_URL;
  }

  get model() {
    return EventBookBindingType;
  }

  get loadParams() {
    return ['configurationId'];
  }
}

export class EventBookDecorationTypesCollection extends LoadableCollection {
  get name() {
    return 'decorationTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_DECORATION_URL;
  }

  get model() {
    return EventBookDecorationType;
  }

  get loadParams() {
    return ['coverType', 'bindingType', 'formatType'];
  }
}

export class EventBookDebossingTypesCollection extends LoadableCollection {
  get name() {
    return 'debossingTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_DEBOSSING_URL;
  }

  get model() {
    return EventBookDebossingType;
  }

  get loadParams() {
    return ['coverType', 'decorationType'];
  }
}

export class EventBookDebossingElementFormatTypesCollection extends LoadableCollection {
  get name() {
    return 'debossingElementFormatTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_DEBOSSING_ELEMENT_URL;
  }

  get model() {
    return EventBookDebossingElementFormatType;
  }

  get loadParams() {
    return [
      'coverType',
      'bindingType',
      'formatType',
      'debossingType',
      'coverMaterialType',
      'coverColorType'
    ];
  }
}

export class EventBookDebossingElementColorTypesCollection extends LoadableCollection {
  get name() {
    return 'debossingElementColorTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_DEBOSSING_ELEMENT_COLOR_URL;
  }

  get model() {
    return EventBookDebossingElementColorType;
  }

  get loadParams() {
    return [
      'coverType',
      'formatType',
      'debossingType',
      'coverMaterialType',
      'coverColorType',
      'debossingElementFormatType'
    ];
  }
}

export class EventBookDebossingTextsCollection extends LoadableCollection {
  get name() {
    return 'debossingTextsCollection';
  }

  get url() {
    return conf.settings.EVENT_BOOK_DEBOSSING_TEXTS_URL;
  }

  get model() {
    return EventBookDebossingTextType;
  }

  get loadParams() {
    return ['coverType', 'formatType', 'debossingType', 'debossingElementFormatType'];
  }
}

export class EventBookEventTypesCollection extends LoadableCollection {
  get name() {
    return 'eventTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_EVENT_TYPE_URL;
  }

  get model() {
    return EventBookEventType;
  }
}

export class EventBookPaperTypesCollection extends LoadableCollection {
  get name() {
    return 'paperTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_PAPER_FINISHING_URL;
  }

  get model() {
    return EventBookPaperType;
  }

  get loadParams() {
    return ['bindingType', 'formatType'];
  }
}

export class EventBookPaperLaminationTypesCollection extends LoadableCollection {
  get name() {
    return 'paperLaminationTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_PAGE_LAMINATION_URL;
  }

  get model() {
    return EventBookPaperLaminationType;
  }

  get loadParams() {
    return ['bindingType', 'paperType'];
  }
}

export class EventBookOrientationTypesCollection extends LoadableCollection {
  get name() {
    return 'orientationTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_ORIENTATION_URL;
  }

  get model() {
    return EventBookOrientationType;
  }

  get loadParams() {
    return ['bindingType'];
  }
}

export class EventBookFormatTypesCollection extends LoadableCollection {
  get name() {
    return 'formatTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_FORMAT_URL;
  }

  get model() {
    return EventBookFormatType;
  }

  get loadParams() {
    return ['bindingType', 'orientationType', 'paperType', 'configurationId'];
  }
}

export class EventBookCoverTypesCollection extends LoadableCollection {
  get name() {
    return 'coverTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_COVER_URL;
  }

  get model() {
    return EventBookCoverType;
  }
}

export class EventBookCoverLaminationTypesCollection extends LoadableCollection {
  get name() {
    return 'coverLaminationTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_COVER_LAMINATION_URL;
  }

  get model() {
    return EventBookCoverLaminationType;
  }

  get loadParams() {
    return ['coverType'];
  }
}

export class EventBookCoverColorMaterialTypesCollection extends LoadableCollection {
  get name() {
    return 'coverColorMaterialTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_COVER_COLORS_MATERIALS_URL;
  }

  get model() {
    return EventBookCoverColorMaterialType;
  }

  get loadParams() {
    return ['coverType', 'bindingType', 'formatType', 'pageSize'];
  }
}

export class EventBookCoverMaterialTypesCollection extends LoadableCollection {
  get name() {
    return 'coverMaterialTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_COVER_MATERIALS_URL;
  }

  get model() {
    return EventBookCoverMaterialType;
  }

  get loadParams() {
    return ['coverType', 'bindingType', 'formatType', 'pageSize'];
  }
}

export class EventBookCoverColorTypesCollection extends LoadableCollection {
  get name() {
    return 'coverColorTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_COVER_COLORS_URL;
  }

  get model() {
    return EventBookCoverColorType;
  }

  get loadParams() {
    return ['coverType', 'bindingType', 'formatType', 'coverMaterialType', 'pageSize'];
  }
}

export class EventBookCoverPaddingCollection extends LoadableCollection {
  get name() {
    return 'coverPaddingTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_COVER_PADDING_URL;
  }

  get model() {
    return EventBookCoverPaddingType;
  }

  get loadParams() {
    return ['coverType'];
  }
}

export class EventBookUvGraphicCollection extends LoadableCollection {
  get name() {
    return 'coverUvGraphicTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_COVER_UV_GRAPHIC_URL;
  }

  get model() {
    return EventBookUvGraphicType;
  }

  get loadParams() {
    return ['coverType', 'decorationType'];
  }
}

export class EventBookUvGraphicThemeCollection extends LoadableCollection {
  get name() {
    return 'coverUvGraphicThemeTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_COVER_UV_GRAPHIC_THEME_URL;
  }

  get model() {
    return EventBookUvGraphicThemeType;
  }

  get loadParams() {
    return ['coverType', 'coverUvGraphicType'];
  }
}

export class EventBookUvGraphicElementCollection extends LoadableCollection {
  get name() {
    return 'coverUvGraphicElementTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_COVER_UV_GRAPHIC_ELEMENT_URL;
  }

  get model() {
    return EventBookUvGraphicElementType;
  }

  get loadParams() {
    return [
      'bindingType',
      'formatType',
      'coverType',
      'coverColorMaterialType',
      'coverMaterialType',
      'coverColorType',
      'coverUvGraphicType',
      'coverUvGraphicThemeType'
    ];
  }
}

export class EventBookUvGraphicTextCollection extends LoadableCollection {
  get bypassDefault() {
    return true;
  }

  get name() {
    return 'coverUvTexts';
  }

  get url() {
    return conf.settings.EVENT_BOOK_COVER_UV_GRAPHIC_TEXT_URL;
  }

  get model() {
    return EventBookUvGraphicText;
  }

  get loadParams() {
    return [
      'formatType',
      'coverType',
      'coverUvGraphicType',
      'coverUvGraphicThemeType',
      'coverUvGraphicElementType'
    ];
  }
}

export class EventBookFlyleafTypeCollection extends LoadableCollection {
  get name() {
    return 'flyleafTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_COVER_FLYLEAF_URL;
  }

  get model() {
    return EventBookFlyleafType;
  }

  get loadParams() {
    return ['bindingType'];
  }
}

export class EventBookFlyleafColorMaterialTypeCollection extends LoadableCollection {
  get name() {
    return 'flyleafColorMaterialTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_COVER_FLYLEAF_COLORS_MATERIALS_URL;
  }

  get model() {
    return EventBookFlyleafColorMaterialType;
  }

  get loadParams() {
    return [
      'bindingType',
      'coverType',
      'flyleafType',
      'coverColorMaterialType',
      'coverMaterialType',
      'coverColorType'
    ];
  }
}

export class EventBookPlaqueTypesCollection extends LoadableCollection {
  get name() {
    return 'plaqueTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_PLAQUE_URL;
  }

  get model() {
    return EventBookPlaqueType;
  }
}

export class EventBookPlaqueTextCollection extends LoadableCollection {
  get bypassDefault() {
    return true;
  }

  get name() {
    return 'plaqueTexts';
  }

  get url() {
    return conf.settings.EVENT_BOOK_PLAQUE_TEXT_URL;
  }

  get model() {
    return EventBookPlaqueText;
  }

  get loadParams() {
    return ['plaqueType'];
  }
}

export class EventBookRasterizeCollection extends BaseCollection {
  constructor(options) {
    super();
    this.configurationId = options.configurationId;
  }

  get url() {
    return conf.settings.EVENT_BOOK_CONFIGURATIONS_URL + this.configurationId + '/rasterize/';
  }

  get model() {
    return EventBookRasterize;
  }
}

export class EventBookCoverProductionInfoCollection extends BaseCollection {
  get name() {
    return 'EventBookCoverProductionInfoCollection';
  }

  get url() {
    return conf.settings.EVENT_BOOK_COVER_PRODUCTION_INFO_URL;
  }

  get model() {
    return EventBookCoverProductionInfo;
  }
}

export class ConfigurationFileCollection extends BaseCollection {
  get url() {
    return conf.settings.EVENT_BOOK_CONFIGURATION_FILES_URL;
  }

  get model() {
    return ConfigurationFile;
  }

  async filterByConfigurationId(configurationId, fileType) {
    return this.fetch({
      data: {
        configurationId: configurationId,
        fileType: fileType,
        softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE
      }
    });
  }
}
