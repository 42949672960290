import { BaseSelectionWidgetWithImages } from '../../core/components/baseSelectionWidgetWithImages';
import React from 'react';
import classNames from 'classnames';
import { sprintf } from '../../core/sprintf';
import { AeMarkupText } from '@photosi/albumepoca-ui';

export class BindingTypesWidget extends BaseSelectionWidgetWithImages {
  get name() {
    return 'bindingType';
  }

  get label() {
    return this.i18n.gettext('rilegatura');
  }

  get hasConfirmModal() {
    return true;
  }

  shouldComponentShowConfirmModal() {
    return (
      this.props.delegate.hasCoverEditorWidget() && this.props.delegate.isCoverEditorProjectValid()
    );
  }

  async onModalConfirmClick() {
    this.props.delegate.resetPhotographicBook();
  }

  handleClick(item) {
    super.handleClick(item);
    if (!this.loading) {
      this.props.delegate.setSheetsnumberMinMax();
    }
  }

  renderContext() {
    let items = [];
    if (this.props.items && this.props.items.map) {
      items = this.props.items.map((item) => {
        let itemClass = classNames('btn-ae-img', 'height-normalizer', {
          active: item.id === this.props.selected
        });

        let text = sprintf(
          this.i18n.gettext('Minimo %s, massimo %s fogli.'),
          item.minSpreadsNumber,
          item.maxSpreadsNumber
        );

        let imageUrl = item.fileUrl;

        return (
          <div
            className="row"
            key={item.id}
            onClick={this.handleClick.bind(this, item)}
            data-action={`set_${this.props.items.name}_${item.id}`}>
            <div className="col-xs-12 col-sm-12 col-md-12 no-padding-col">
              <div className={itemClass}>
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 option__thumb option__thumb_padding">
                    {imageUrl ? <img src={imageUrl} /> : null}
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 no-padding-col">
                    <div className="option__title_padding__fixed">{item.name}</div>
                    <div className="option__subtitle">
                      <AeMarkupText text={text} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }

    return (
      <div className="product__formats" data-product-formats="">
        {items}
      </div>
    );
  }
}
