import React from 'react';

import { BaseContentView, BaseProductView, BaseFiltersView } from '../core/views';
import Logger from '../core/logger';

import { ProjectNameWidget } from '../core/components/projectNameWidget';
import { MaterialPreview } from '../core/components/materialPreview';
import { PhotographicPreview3d } from '../core/components/photographicPreview3d';

import { BindingTypesWidget } from './components/bindingTypesWidget';
import { FormatTypesWidget } from './components/formatTypesWidget';
import { PaperTypesWidget } from './components/paperTypesWidget';
import { PaperLaminationTypesWidget } from './components/paperLaminationTypesWidget';
import { OrientationTypesWidget } from './components/orientationTypesWidget';
import { CoverTypesWidget } from './components/coverTypesWidget';
import { CoverLaminationTypesWidget } from './components/coverLaminationTypesWidget';
import { CoverPaddingWidget } from './components/coverPaddingWidget';
import { UvGraphicWidget } from './components/uvGraphicWidget';
import { UvGraphicThemeWidget } from './components/uvGraphicThemeWidget';
import { UvGraphicElementWidget } from './components/uvGraphicElementWidget';
import { UvGraphicTextWidget } from './components/uvGraphicTextWidget';
import { PlaqueTypeWidget } from './components/plaqueTypeWidget';
import { PlaqueTextWidget } from './components/plaqueTextWidget';
import { FlyleafTypesWidget } from './components/flyleafTypesWidget';
import { FlyleafColorMaterialTypesWidget } from './components/flyleafColorMaterialTypesWidget';
import { PhotographicCoverEditorWidget } from './components/photographicCoverEditorWidget';
import { SheetsNumberWidget } from './components/sheetsNumberWidget';
import { FiltersTitle } from '../core/components/filtersTitle';
import { EventTypesWidget } from './components/eventTypesWidget';
import { CoverMaterialTypesWidget } from './components/coverMaterialTypesWidget';
import { CoverColorTypesWidget } from './components/coverColorTypesWidget';
import { DecorationTypesWidget } from './components/decorationTypesWidget';
import { DebossingWidget } from './components/debossingWidget';
import { DebossingElementFormatWidget } from './components/debossingElementFormatWidget';
import { DebossingTextWidget } from './components/debossingTextWidget';
import { DebossingElementSuggestedColorsWidget } from './components/debossingElementSuggestedColorsWidget';
import { DebossingElementColorsWidget } from './components/debossingElementColorsWidget';

class ProductView extends BaseProductView {
  priceIsLoading() {
    let priceIsLoading;
    if (this.props.updatePrice) {
      priceIsLoading = false;
    } else {
      priceIsLoading =
        this.props.loadingCollection.eventBookPrices || this.props.productConfigurationIsLoading;
    }
    return priceIsLoading;
  }

  renderPreview() {
    return (
      <div className="row product-preview">
        <div className="col-xs-12">
          {this.props.delegate.hasCoverEditorWidget() ? (
            <PhotographicPreview3d
              delegate={this.props.delegate}
              url={this.props.delegate.player3dApplicationUrl}
              loading={this.props.loadingPreview}
              height={this.state.productPreviewHeight}
              width={this.state.productPreviewWidth}
            />
          ) : (
            <MaterialPreview
              product={this.props.product}
              height={this.state.productPreviewHeight}
              width={this.state.productPreviewWidth}
            />
          )}
        </div>
      </div>
    );
  }
}

class FiltersView extends BaseFiltersView {
  getFilters() {
    return [
      <FiltersTitle
        key="FiltersTitle"
        title={this.i18n.gettext('Personalizza il tuo fotolibro per eventi')}
      />,

      <ProjectNameWidget
        key="ProjectNameWidget"
        delegate={this.delegate}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
      />,

      <EventTypesWidget
        key="EventTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.eventTypes}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <CoverTypesWidget
        key="CoverTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.coverTypes}
        selected={this.props.configuration.coverType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <BindingTypesWidget
        key="BindingTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.bindingTypes}
        selected={this.props.configuration.bindingType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <SheetsNumberWidget
        key="SheetsNumberWidget"
        delegate={this.delegate}
        items={this.props.filters.sheetsNumber || { min: 0, max: 50 }}
        selected={this.props.configuration.sheetsNumber}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PaperTypesWidget
        key="PaperTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.paperTypes}
        selected={this.props.configuration.paperType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PaperLaminationTypesWidget
        key="PaperLaminationTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.paperLaminationTypes}
        selected={this.props.configuration.paperLaminationType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <OrientationTypesWidget
        key="OrientationTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.orientationTypes}
        selected={this.props.configuration.orientationType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <FormatTypesWidget
        key="FormatTypesWidget"
        delegate={this.delegate}
        unitOfMeasure={this.delegate.unitOfMeasure}
        items={this.props.filters.formatTypes}
        selected={this.props.configuration.formatType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <CoverMaterialTypesWidget
        key="CoverMaterialTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.coverMaterialTypes}
        selected={this.props.configuration.coverMaterialType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <CoverColorTypesWidget
        key="CoverColorTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.coverColorTypes}
        selected={this.props.configuration.coverColorType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <CoverLaminationTypesWidget
        key="CoverLaminationTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.coverLaminationTypes}
        selected={this.props.configuration.coverLaminationType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <CoverPaddingWidget
        key="CoverPaddingWidget"
        delegate={this.delegate}
        items={this.props.filters.coverPaddingTypes}
        selected={this.props.configuration.coverPaddingType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <DecorationTypesWidget
        key="DecorationTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.decorationTypes}
        selected={this.props.configuration.decorationType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <DebossingWidget
        key="DebossingWidget"
        delegate={this.delegate}
        items={this.props.filters.debossingTypes}
        selected={this.props.configuration.debossingType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <DebossingElementFormatWidget
        key="DebossingElementFormatWidget"
        delegate={this.delegate}
        items={this.props.filters.debossingElementFormatTypes}
        selected={this.props.configuration.debossingElementType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <DebossingTextWidget
        key="DebossingTextWidget"
        delegate={this.delegate}
        items={this.props.filters.debossingTextsCollection}
        value={this.props.configuration.debossingTexts}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <DebossingElementSuggestedColorsWidget
        key="DebossingElementSuggestedColorsWidget"
        delegate={this.delegate}
        items={this.props.filters.debossingElementColorTypes}
        selected={this.props.configuration.debossingElementColorType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <DebossingElementColorsWidget
        key="DebossingElementColorsWidget"
        delegate={this.delegate}
        items={this.props.filters.debossingElementColorTypes}
        selected={this.props.configuration.debossingElementColorType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <UvGraphicWidget
        key="UvGraphicWidget"
        delegate={this.delegate}
        items={this.props.filters.coverUvGraphicTypes}
        selected={this.props.configuration.coverUvGraphicType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <UvGraphicThemeWidget
        key="UvGraphicThemeWidget"
        delegate={this.delegate}
        items={this.props.filters.coverUvGraphicThemeTypes}
        selected={this.props.configuration.coverUvGraphicThemeType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <UvGraphicElementWidget
        key="UvGraphicElementWidget"
        delegate={this.delegate}
        items={this.props.filters.coverUvGraphicElementTypes}
        selected={this.props.configuration.coverUvGraphicElementType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <UvGraphicTextWidget
        key="UvGraphicTextWidget"
        delegate={this.delegate}
        items={this.props.filters.coverUvTexts}
        value={this.props.configuration.coverUvTexts}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PhotographicCoverEditorWidget
        key="PhotographicCoverEditorWidget"
        delegate={this.delegate}
        showCoverEditor={this.delegate.showCoverEditor}
        coverEditorApplicationUrl={this.delegate.coverEditorUrl}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <FlyleafTypesWidget
        key="FlyleafTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.flyleafTypes}
        selected={this.props.configuration.flyleafType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <FlyleafColorMaterialTypesWidget
        key="FlyleafColorMaterialTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.flyleafColorMaterialTypes}
        selected={this.props.configuration.flyleafColorMaterialType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PlaqueTypeWidget
        key="PlaqueTypeWidget"
        delegate={this.delegate}
        items={this.props.filters.plaqueTypes}
        selected={this.props.configuration.plaqueType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PlaqueTextWidget
        key="PlaqueTextWidget"
        delegate={this.delegate}
        items={this.props.filters.plaqueTexts}
        value={this.props.configuration.plaqueTexts}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />
    ];
  }
}

export class EventbookContentView extends BaseContentView {
  render() {
    return super.render(ProductView, FiltersView);
  }

  constructor(props) {
    super(props);
    this.handleIFrameMessage = this.handleIFrameMessage.bind(this);
  }

  componentDidMount() {
    window.addEventListener('message', this.handleIFrameMessage, false);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleIFrameMessage, false);
  }

  handleIFrameMessage(event) {
    //var origin = event.origin || event.originalEvent.origin; // For Chrome, the origin property is in the event.originalEvent object.
    if (event.data.message === 'Player3dSaveImageComplete') {
      Logger.info('Player3dSaveImageComplete', 'received');
      let image = event.data.data.image;
      this.delegate.saveCoverPreview(image);
    }
    if (event.data.message === 'Player3dSaveImageError') {
      Logger.info('Player3dSaveImageError', 'received');
    }

    if (event.data.message === 'CloseCoverEditorProject') {
      Logger.info('CloseCoverEditorProject', 'received');
      this.delegate.hideCoverEditorIframe();
    }

    if (event.data.message === 'SaveCoverEditorProject') {
      Logger.info('SaveCoverEditorProject', 'received');
      this.delegate.rasterizeCoverSvg(event.data.data.projectId, event.data.data.coverId);
      this.delegate.hideCoverEditorIframe();
    }

    if (event.data.message === 'Player3dExportTemplateComplete') {
      Logger.info('Player3dExportTemplateComplete', 'received');
      let image = event.data.data.image;
      let fileExtension = event.data.data.fileExtension || 'jpg';
      this.delegate.saveCoverTemplate(image, fileExtension);
    }
  }
}
