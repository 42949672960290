import { BaseSelectionWidgetWithImages } from '../../core/components/baseSelectionWidgetWithImages';

export class CoverPaddingWidget extends BaseSelectionWidgetWithImages {
  get name() {
    return 'coverPaddingType';
  }

  get label() {
    return this.i18n.gettext('imbottitura copertina');
  }

  get hasConfirmModal() {
    return true;
  }

  shouldComponentShowConfirmModal() {
    return (
      this.props.delegate.isHotBindingSelected() &&
      this.props.delegate.hasCoverEditorWidget() &&
      this.props.delegate.isCoverEditorProjectValid()
    );
  }

  async onModalConfirmClick() {
    this.props.delegate.resetPhotographicBook();
  }
}
