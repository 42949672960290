import { GraphicCollapsableWithDescriptionWidget } from '../../core/components/graphicCollapsableWithDescriptionWidget';
// import {GraphicCollapsableWidgetWithText} from '../../core/components/graphicCollapsableWidgetWithText';

export class FlyleafColorMaterialTypesWidget extends GraphicCollapsableWithDescriptionWidget {
  get name() {
    return 'flyleafColorMaterialType';
  }

  get label() {
    return this.i18n.gettext('colore risguardo');
  }

  get calculatePrice() {
    return false;
  }

  get showDescription() {
    return (
      this.delegate.configuration &&
      this.delegate.configuration.flyleafType &&
      this.delegate.configuration.flyleafType !== 'FLYLEAF_STANDARD'
    );
  }

  get descriptionSubTitle() {
    return this.i18n.gettext('Flyleaf color material description');
  }

  get deprecatedDescription() {
    return this.i18n.gettext(
      'I risguardi degli Event Book Rivestiti sono realizzate con i seguenti materiali: Leonardo con un materiale che simula l’effetto della pelle dello squalo Michelangelo da un tessuto di viscosa Raffaello da un materiale soffice e vellutato Tiziano da un tessuto in lino colore naturale.'
    );
  }

  // get textDescription() {
  //   let text = '';
  //   if (this.delegate.configuration && this.delegate.configuration.flyleafType && this.delegate.configuration.flyleafType !== 'FLYLEAF_STANDARD') {
  //     text = this.i18n.gettext('I risguardi degli Event Book Rivestiti sono realizzate con i seguenti materiali: Leonardo con un materiale che simula l’effetto della pelle dello squalo Michelangelo da un tessuto di viscosa Raffaello da un materiale soffice e vellutato Tiziano da un tessuto in lino colore naturale.');
  //   }
  //   return text;
  // }
}
