import { BaseSelectionWidgetTwoColumns } from '../../core/components/baseSelectionWidgetTwoColumns';

export class PaperTypesWidget extends BaseSelectionWidgetTwoColumns {
  get name() {
    return 'paperType';
  }

  get label() {
    return this.i18n.gettext('carta');
  }

  get hasConfirmModal() {
    return true;
  }

  shouldComponentShowConfirmModal() {
    return (
      this.props.delegate.isHotBindingSelected() &&
      this.props.delegate.hasCoverEditorWidget() &&
      this.props.delegate.isCoverEditorProjectValid()
    );
  }

  async onModalConfirmClick() {
    this.props.delegate.resetPhotographicBook();
  }
}
